import React from 'react';

const useDebounce = <T>(value: T, wait: number) => {
  const [debouncedValue, setDebouncedValue] = React.useState(value);

  React.useEffect(() => {
    const timeout = setTimeout(() => setDebouncedValue(value), wait);

    return () => {
      clearTimeout(timeout);
    };
  }, [value, wait]);

  return debouncedValue;
};

export default useDebounce;
