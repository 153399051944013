import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import AsyncClientAutoComplete from 'containers/FormFields/AsyncClientAutoComplete';
import AsyncObraAutoComplete from 'containers/FormFields/AsyncObraAutoComplete';
import esLocale from 'date-fns/locale/es';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';

const query_state = (state: RootState) => ({
  orden_tomada_en: state.global.data.orden_tomada_en,
  especificaciones: state.global.data.especificaciones,
  sedes: state.global.data.sedes,
});

interface Tab1Props {
  datos: any;
  handle_change: (v1: any, v2: any) => any;
}

export default function Tab1(props: Tab1Props) {
  const { datos, handle_change } = props;
  const { orden_tomada_en, especificaciones, sedes } = useSelector(query_state);

  const [datos_iniciales] = React.useState(datos);
  React.useEffect(() => {
    const obra =
      datos.cliente === datos_iniciales.cliente ? datos_iniciales.obra : null;
    handle_change('obra', obra);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datos.cliente]);
  React.useEffect(() => {
    if (!datos.id) {
      handle_change('ubicacion_muestras', datos.sede);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datos.sede, datos.id]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={4}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
          <DateTimePicker
            label="Fecha recibido"
            format="dd/MM/yyyy"
            value={datos.fecha_recibido}
            onChange={(fecha) => {
              handle_change('fecha_recibido', fecha);
            }}
            autoOk
            cancelLabel="Cancelar"
            required
            disableFuture
            maxDateMessage="La fecha no debe ser mayor al día de hoy"
            style={{ width: '100%' }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Autocomplete
          value={datos.sede}
          onChange={(event, valor) => {
            handle_change('sede', valor);
          }}
          options={sedes.ids}
          getOptionLabel={(id) => sedes.por_id[id].nombre}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Sede"
              helperText="Sede donde se recibio la orden"
              required
            />
          )}
          disableClearable
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Autocomplete
          value={datos.ubicacion_muestras}
          onChange={(event, valor) => {
            handle_change('ubicacion_muestras', valor);
          }}
          options={sedes.ids}
          getOptionLabel={(id) => sedes.por_id[id].nombre}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Ubicación muestras"
              helperText="Sede donde estan las muestras"
              required
            />
          )}
          disableClearable
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <AsyncClientAutoComplete
          client_id={datos.cliente}
          on_change={(event, valor) => {
            handle_change('cliente', valor);
          }}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <AsyncObraAutoComplete
          value={datos.obra}
          client_id={datos.cliente}
          on_change={(event, valor) => {
            handle_change('obra', valor);
          }}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Responsable"
          name="responsable"
          value={datos.responsable}
          onChange={(event) => {
            handle_change('responsable', event.target.value);
          }}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Contacto"
          name="contacto"
          value={datos.contacto}
          onChange={(event) => {
            handle_change('contacto', event.target.value);
          }}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Autocomplete
          value={datos.especificacion}
          onChange={(event, valor) => {
            handle_change('especificacion', valor);
          }}
          options={especificaciones.ids}
          getOptionLabel={(id) => especificaciones.por_id[id].nombre}
          renderInput={(params) => (
            <TextField {...params} label="Especificación" />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Autocomplete
          value={datos.tomada_en}
          onChange={(event, valor) => {
            handle_change('tomada_en', valor);
          }}
          options={orden_tomada_en.ids}
          getOptionLabel={(option) => orden_tomada_en.por_id[option].nombre}
          renderInput={(params) => (
            <TextField {...params} label="Tomado en" required />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField
          label="Observaciones"
          name="observaciones"
          value={datos.observaciones}
          onChange={(event) => {
            handle_change('observaciones', event.target.value);
          }}
          variant="outlined"
          placeholder=""
          multiline
          rows={2}
          rowsMax={4}
          fullWidth
        />
      </Grid>
    </Grid>
  );
}
