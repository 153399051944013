import DateFnsUtils from '@date-io/date-fns';
import { FormControl, FormControlLabel } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ModelOrdenServicioEdit } from 'api/types';
import InputFile from 'components/Input/InputFile';
import media_types from 'config/media_types';
import { DEPARTAMENTO_TECNICO } from 'config/user_groups';
import esLocale from 'date-fns/locale/es';
import React from 'react';
import { useSelector } from 'react-redux';
import { token_verify_roles } from 'redux/auth/reducers';
import { RootState } from 'redux/reducers';

const query_state = (state: RootState) => ({
  estados_tecnicos: state.global.data.estados_tecnicos,
  token_verify_roles: token_verify_roles(state),
});

interface Tab2Props {
  datos: ModelOrdenServicioEdit;
  handle_change: (v1: any, v2: any) => any;
}

export default function Tab2(props: Tab2Props) {
  const { datos, handle_change } = props;
  const { estados_tecnicos, token_verify_roles } = useSelector(query_state);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        <FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={Boolean(datos.genera_informe)}
                onChange={(event, checked) => {
                  handle_change('genera_informe', checked);
                }}
                color="secondary"
              />
            }
            label="Genera informe"
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Autocomplete
          value={datos.estado_tecnico}
          onChange={(event, valor) => {
            handle_change('estado_tecnico', valor);
          }}
          options={estados_tecnicos.ids}
          getOptionLabel={(id) => estados_tecnicos.por_id[id].nombre}
          renderInput={(params) => (
            <TextField {...params} label="Estado técnico" />
          )}
          disabled={!token_verify_roles([DEPARTAMENTO_TECNICO])}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
          <DateTimePicker
            label="Fecha real fin cálculo y digitación"
            format="dd/MM/yyyy"
            value={datos.fecha_real_fin_calculo_digitacion}
            onChange={(fecha) => {
              handle_change('fecha_real_fin_calculo_digitacion', fecha);
            }}
            autoOk
            cancelLabel="Cancelar"
            clearable={true}
            clearLabel="Limpiar"
            disableFuture
            maxDateMessage="La fecha no debe ser mayor al día de hoy"
            style={{ width: '100%' }}
          />
        </MuiPickersUtilsProvider>
      </Grid>

      <Grid item xs={12} sm={6}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
          <DateTimePicker
            label="Fecha real salida informe"
            format="dd/MM/yyyy"
            value={datos.fecha_real_salida_informe}
            onChange={(fecha) => {
              handle_change('fecha_real_salida_informe', fecha);
            }}
            autoOk
            cancelLabel="Cancelar"
            clearable={true}
            clearLabel="Limpiar"
            disableFuture
            maxDateMessage="La fecha no debe ser mayor al día de hoy"
            style={{ width: '100%' }}
            disabled={
              !token_verify_roles([DEPARTAMENTO_TECNICO]) ||
              !Boolean(datos.genera_informe)
            }
          />
        </MuiPickersUtilsProvider>
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          label="Número de informe"
          name="numero_informe"
          value={datos.numero_informe}
          onChange={(event) => {
            handle_change('numero_informe', event.target.value);
          }}
          fullWidth
          disabled={
            !token_verify_roles([DEPARTAMENTO_TECNICO]) ||
            !Boolean(datos.genera_informe)
          }
        />
      </Grid>

      <Grid item xs={12} sm={12}>
        <InputFile
          file={datos.informe}
          accept={[media_types.pdf, media_types.word].join(',')}
          tooltip_title="Subir informe"
          on_change={(file) => {
            handle_change('informe', file);
          }}
          disabled={
            !token_verify_roles([DEPARTAMENTO_TECNICO]) ||
            !Boolean(datos.genera_informe)
          }
        />
      </Grid>
    </Grid>
  );
}
