import { createMuiTheme } from '@material-ui/core/styles';
import pink from '@material-ui/core/colors/pink';
import { esES } from '@material-ui/core/locale';

const theme = createMuiTheme(
  {
    palette: {
      primary: {
        main: '#00897b',
      },
      secondary: pink,
    },
  },
  esES,
);

export default theme;
