import { StatisticCommentType } from 'api/consts';
import * as Api from 'api/api';
import { AppThunk } from 'redux/store';
import {
  ActionSetComment,
  ActionSetOrders,
  ActionSetYearFilter,
  action_types,
} from './types';
import { handle_http_error, set_loading } from 'redux/global/actions';
import { NormalizedModelOrdenServicio } from 'api/types';
import { date_final_hour_day, date_initial_hour_day } from 'utilities/general';

///////////////////////////////////////////////////////////////////////////////
// Action Creattors
///////////////////////////////////////////////////////////////////////////////

export const set_year_filter = (year: Date): ActionSetYearFilter => ({
  type: action_types.SET_YEAR_FILTER,
  year,
});

export const set_comments = (
  type_comment: StatisticCommentType,
  comments: string,
): ActionSetComment => ({
  type: action_types.SET_COMMENTS,
  type_comment,
  comments,
});

const set_orders = (orders: NormalizedModelOrdenServicio): ActionSetOrders => ({
  type: action_types.SET_ORDERS,
  orders,
});

///////////////////////////////////////////////////////////////////////////////
// Redux thunk
///////////////////////////////////////////////////////////////////////////////

export const get_comments = (type_comment: StatisticCommentType): AppThunk => (
  dispatch,
  getState,
) => {
  const { filter_year } = getState().statistics.ui;
  const year = filter_year.getFullYear();

  dispatch(set_loading(true));
  Api.StatisticComment.detail(year, type_comment)
    .then((response) => {
      const comments: string = response.data.comentario;
      dispatch(set_comments(type_comment, comments));
    })
    .catch((error) => {
      dispatch(handle_http_error(error));
    })
    .finally(() => {
      dispatch(set_loading(false));
    });
};

export const save_comments = (
  type_comment: StatisticCommentType,
  comments: string,
): AppThunk => (dispatch, getState) => {
  const year = getState().statistics.ui.filter_year.getFullYear();

  dispatch(set_comments(type_comment, comments));

  dispatch(set_loading(true));
  Api.StatisticComment.update(year, type_comment, comments)
    .then((response) => {})
    .catch((error) => {
      dispatch(handle_http_error(error));
    })
    .finally(() => {
      dispatch(set_loading(false));
    });
};

export const get_orders = (): AppThunk => (dispatch, getState) => {
  const year = getState().statistics.ui.filter_year.getFullYear();

  const initial_date = date_initial_hour_day(new Date(year, 0, 1));
  const final_date = date_final_hour_day(new Date(year, 11, 31));
  const filters = {
    fecha_inicial: initial_date,
    fecha_final: final_date,
  } as const;

  dispatch(set_loading(true));
  Api.OrdenServicio.listar(filters)
    .then((response) => {
      dispatch(set_orders(response.data));
    })
    .catch((error) => {
      handle_http_error(error);
    })
    .finally(() => {
      dispatch(set_loading(false));
    });
};
