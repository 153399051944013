export enum Months {
  Enero = 0,
  Febrero = 1,
  Marzo = 2,
  Abril = 3,
  Mayo = 4,
  Junio = 5,
  Julio = 6,
  Agosto = 7,
  Septiembre = 8,
  Octubre = 9,
  Noviembre = 10,
  Diciembre = 11,
}

enum MonthsShortcuts {
  EN = 0,
  FB = 1,
  MR = 2,
  AB = 3,
  MY = 4,
  JN = 5,
  JL = 6,
  AG = 7,
  SP = 8,
  OC = 9,
  NV = 10,
  DC = 11,
}

/**
 * @param _date Fecha con formato ISO 8601
 * @param short true para formato corto, de lo contrario false
 */
export function format_full_date(_date: string, short: boolean = true): string {
  const date = new Date(_date);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    hour12: true,
    minute: 'numeric',
  };
  options.month = short ? 'short' : 'long';

  if (short)
    return date.toLocaleDateString('es-CO', options).replace(/de /g, '');

  return date.toLocaleDateString('es-CO', options);
}

export function get_month_shortcut(_date: string): string {
  const date = new Date(_date);
  const shortcut = MonthsShortcuts[date.getMonth()];
  return shortcut;
}

export class TimeDelta {
  seconds: number = 0;
  minutes: number = 0;
  hours: number = 0;
  days: number = 0;

  private regex = /(-?\d+\s)?(\d{1,2}):(\d{1,2}):(\d{1,2})/;

  constructor(value: string) {
    const match = value.match(this.regex);
    if (match) {
      this.days = match[1] ? parseInt(match[1]) : 0;
      this.hours = match[2] ? parseInt(match[2]) : 0;
      this.minutes = match[3] ? parseInt(match[3]) : 0;
      this.seconds = match[4] ? parseInt(match[4]) : 0;
    }
  }

  toString = () => {
    const days = `${this.days} días `;
    const hours = `${this.hours} H`;
    const minutes = `${this.minutes} m`;
    const seconds = `${this.seconds} s`;

    return `${days} ${hours}:${minutes}:${seconds}`;
  };
}
