import {
  Card,
  CardHeader,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import MDEditor from '@uiw/react-md-editor';
import EditButton from 'components/Buttons/EditButton';
import DialogMDEditor from 'components/Dialogs/DialogMDEditor';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    chartTitle: {
      marginBottom: theme.spacing(3),
    },
    mdViewer: {
      border: 'solid 1px',
      borderColor: theme.palette.grey[300],
      borderRadius: 3,
      padding: theme.spacing(0.8),
      overflowY: 'auto',
    },
  }),
);

interface Props {
  title: string;
  height: number;
  edit_mode?: boolean;
  value: string;
  on_save: (value: string) => void;
}

export default function CardComments(props: Props) {
  const classes = useStyles();
  const { title, height, edit_mode, value, on_save } = props;
  const md_editor_height = height * 0.86;
  const [open_edit, set_open_edit] = React.useState(false);

  const handle_save = (value: string) => {
    set_open_edit(false);
    on_save(value);
  };

  const handle_cancel = () => {
    set_open_edit(false);
  };

  return (
    <>
      <Card className={classes.root} style={{ height: height }}>
        <CardHeader
          title={
            <Typography
              className={classes.chartTitle}
              component="h2"
              variant="h6"
              color="primary"
              gutterBottom>
              {title}
            </Typography>
          }
          action={
            edit_mode && (
              <EditButton
                onClick={(event) => {
                  set_open_edit(true);
                }}
              />
            )
          }
        />

        <div className={classes.mdViewer} style={{ height: md_editor_height }}>
          <MDEditor.Markdown source={value} />
        </div>
      </Card>

      {open_edit && (
        <DialogMDEditor
          value={value}
          on_cancel={handle_cancel}
          on_save={handle_save}
        />
      )}
    </>
  );
}
