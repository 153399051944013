import { Grid } from '@material-ui/core';
import React from 'react';
import CardFilter from './CardFilter';
import CardOrderList from './CardOrderList';

function OrdenServicioListar() {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={7} lg={8}>
        <CardOrderList />
      </Grid>
      <Grid item xs={12} sm={12} md={5} lg={4}>
        <CardFilter />
      </Grid>
    </Grid>
  );
}

export default OrdenServicioListar;
