import { StatisticCommentType } from 'api/consts';
import produce from 'immer';
import { RootState } from 'redux/reducers';
import { action_types, StatisticsActions, StatisticsState } from './types';

const initial_state: StatisticsState = {
  ui: {
    filter_year: new Date(),
  },
  data: {
    comments: {
      1: '',
      2: '',
      3: '',
    },
    orders: null,
  },
} as const;

const statistics = (state = initial_state, action: StatisticsActions) =>
  produce(state, (draft) => {
    switch (action.type) {
      case action_types.SET_YEAR_FILTER:
        draft.ui.filter_year = action.year;
        break;
      case action_types.SET_COMMENTS:
        draft.data.comments[action.type_comment] = action.comments;
        break;
      case action_types.SET_ORDERS:
        draft.data.orders = action.orders;
        break;
      default:
        return draft;
    }
  });

export default statistics;

export const state_get_comments = (
  state: RootState,
  type_comment: StatisticCommentType,
) => state.statistics.data.comments[type_comment];
