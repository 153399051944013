import { IconButton, Tooltip } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

interface Props {
  title?: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export default function EditButton(props: Props) {
  const { title = 'Editar', onClick } = props;
  return (
    <Tooltip title={title}>
      <IconButton onClick={onClick}>
        <EditIcon color="primary" />
      </IconButton>
    </Tooltip>
  );
}
