import { Token } from 'api/types';
import { Action } from 'redux';

export const action_types = {
  SET_LOADING: 'SET_LOADING',
  SET_LOGIN: 'SET_LOGIN',
  SET_LOGIN_ERROR: 'SET_LOGIN_ERROR',
  SET_REFRESH_TOKEN_ERROR: 'SET_REFRESH_TOKEN_ERROR',
} as const;

export interface AuthState {
  token: Token | null;
  login_error: boolean | null;
  refrescar_token_error: FixLater | null;
}

export interface ActionSetLoading extends Action {
  type: typeof action_types.SET_LOADING;
  value: boolean;
}

export interface ActionSetLoginError extends Action {
  type: typeof action_types.SET_LOGIN_ERROR;
  error: boolean | null;
}

export interface ActionSetLogin extends Action {
  type: typeof action_types.SET_LOGIN;
  token: Token | null;
}

export interface ActionSetRefreshTokenError extends Action {
  type: typeof action_types.SET_REFRESH_TOKEN_ERROR;
  error: boolean | null;
}

export type AuthActions =
  | ActionSetLoading
  | ActionSetLoginError
  | ActionSetLogin
  | ActionSetRefreshTokenError;
