export enum StatisticCommentType {
  LABORATORIO = 1,
  CALCULO_DIGITACION = 2,
  INFORME = 3,
}

export enum EstadoOrdenLaboratorio {
  CREADA = 1,
  APROBADA = 2,
  ANULADA = 3,
  EN_CURSO_A_TIEMPO = 4,
  EN_CURSO_ATRASADO = 5,
  TERMINADO_A_TIEMPO = 6,
  TERMINADO_ATRASADO = 7,
  TERMINADO_ADELANTADO = 8,
}
