/*-------------------------------------------------------------
 *               PROJECT CONFIG FILE TEMPLATE
 *
 *   Copy this file as "config.ts" and set the config values
 *-------------------------------------------------------------*/

export const API_BASE_URL = 'https://app.suelospavimentos.com/api';

// Must be the same value configured in the API (Seconds)
export const ACCESS_TOKEN_LIFETIME = 5 * 60;

// Must be at least 60 seconds less than ACCESS_TOKEN_LIFETIME (Seconds)
export const TIME_TO_REFRESH_TOKEN = ACCESS_TOKEN_LIFETIME - 60;
