import {
  Card,
  createStyles,
  Divider,
  Fab,
  Theme,
  Tooltip,
} from '@material-ui/core';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import { Pagination } from '@material-ui/lab';
import { ModelOrdenServicio, ModelOrdenServicioListar } from 'api/types';
import AlertDialog from 'components/Dialogs/AlertDialog';
import { LISTA_ROLES_CREAR_EDITAR } from 'config/user_groups';
import ProtectedComponent from 'containers/auth/ProtectedComponent';
import DialogCrearOrden from 'containers/Dialogs/DialogOrden/DialogCrearOrden';
import DialogEditOrder from 'containers/Dialogs/DialogOrden/DialogEditarOrden';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { get_edit_mode } from 'redux/global/reducers';
import {
  definir_paginacion,
  eliminar_orden,
  obtener_ordenes_servicios,
} from 'redux/orden_servicio/actions';
import {
  obtener_numero_paginas,
  query_filters,
} from 'redux/orden_servicio/reducers';
import { RootState } from 'redux/reducers';
import { useTypedDispatch } from 'redux/store';
import ItemOrder from './ItemOrder';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      position: 'absolute',
      bottom: theme.spacing(20),
      right: theme.spacing(2),
      zIndex: 1000,
    },
    pagination: {
      marginTop: theme.spacing(2),
      '& > *': {
        justifyContent: 'center',
      },
    },
  }),
);

const query_selector = (state: RootState) => ({
  paginacion: state.orden_servicio.ui.paginacion,
  ordenes_servicios: state.orden_servicio.datos.ordenes_servicios,
  numero_paginas: obtener_numero_paginas(state),
  edit_mode: get_edit_mode(state),
  filters: query_filters(state),
});

export default function OrderList() {
  const classes = useStyles();
  const redux_state = useSelector(query_selector);
  const dispatch = useTypedDispatch();

  useEffect(() => {
    dispatch(obtener_ordenes_servicios(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redux_state.paginacion.page, ...Object.values(redux_state.filters)]);

  // For add order
  const [open_add_order, set_open_add_order] = React.useState(false);
  const handle_open_add_order = () => {
    set_open_add_order(true);
  };
  const handle_close_add_order = () => {
    set_open_add_order(false);
  };

  // For edit order
  const [
    edit_order,
    set_edit_order,
  ] = React.useState<ModelOrdenServicio | null>(null);
  const handle_open_edit_order = (_order: ModelOrdenServicioListar) => (
    event: any,
  ) => {
    event.preventDefault();
    const order: ModelOrdenServicio = {
      ..._order,
      obra: _order.obra.id,
      cliente: _order.obra.cliente.id,
    };
    set_edit_order(order);
  };
  const handle_close_edit_order = () => {
    set_edit_order(null);
  };

  // For delete order
  const [delete_order, set_delete_order] = React.useState<number | null>(null);
  const handle_open_delete_order = (order_id: number) => (event: any) => {
    event.preventDefault();
    set_delete_order(order_id);
  };
  const handle_accept_delete_order = () => {
    dispatch(eliminar_orden(delete_order as number)).then(() => {
      set_delete_order(null);
    });
  };
  const handle_cancel_delete_order = () => {
    set_delete_order(null);
  };

  return (
    <>
      <Card>
        <Typography component="h1" variant="h4" align="center">
          Ordenes de servicio
        </Typography>

        {redux_state.edit_mode && (
          <ProtectedComponent roles={LISTA_ROLES_CREAR_EDITAR}>
            <Tooltip title="Crear orden de servicio">
              <Fab
                className={classes.fab}
                color="primary"
                onClick={handle_open_add_order}>
                <AddIcon style={{ fontSize: 42 }} />
              </Fab>
            </Tooltip>
          </ProtectedComponent>
        )}

        <List>
          {redux_state.ordenes_servicios.ids.map((id, index, list) => (
            <div key={id}>
              <ItemOrder
                order={redux_state.ordenes_servicios.por_id[id]}
                handle_open_edit_order={handle_open_edit_order}
                handle_open_delete_order={handle_open_delete_order}
                edit_mode={redux_state.edit_mode}
              />
              {index < list.length - 1 && <Divider />}
            </div>
          ))}
        </List>
      </Card>

      <DialogCrearOrden
        open={open_add_order}
        on_close={handle_close_add_order}
      />

      {edit_order && (
        <DialogEditOrder
          open={Boolean(edit_order)}
          orden_servicio={edit_order}
          on_cancel={handle_close_edit_order}
        />
      )}

      <AlertDialog
        open={delete_order !== null}
        onAccept={handle_accept_delete_order}
        onCancel={handle_cancel_delete_order}
        title="¿Esta seguro?"
        content="¿Desea eliminar esta orden?"
      />

      {redux_state.numero_paginas && redux_state.numero_paginas > 1 && (
        <Pagination
          count={redux_state.numero_paginas}
          page={redux_state.paginacion.page}
          onChange={(event, page) => {
            dispatch(definir_paginacion({ page }));
          }}
          className={classes.pagination}
          color="secondary"
        />
      )}
    </>
  );
}
