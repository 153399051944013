import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { LISTA_ROLES_CREAR_EDITAR } from 'config/user_groups';
import LaboratoryCompliancePercentaje from 'containers/charts/LaboratoryCompliancePercentaje';
import ChartLaboratoryAverageLate from 'containers/charts/LaboratoryAverageLate';
import React from 'react';
import { useSelector } from 'react-redux';
import { token_verify_roles } from 'redux/auth/reducers';
import { get_edit_mode } from 'redux/global/reducers';
import { RootState } from 'redux/reducers';
import Orders from './Orders';
import CardComments from './CardComments';
import { StatisticCommentType } from 'api/consts';
import { useTypedDispatch } from 'redux/store';
import { state_get_comments } from 'redux/statistics/reducers';
import {
  get_comments,
  get_orders,
  save_comments,
} from 'redux/statistics/actions';

const paper_chart_heigh = 250;
const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(1),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    fixedHeight: {
      height: paper_chart_heigh,
    },
    chartTitle: {
      marginBottom: theme.spacing(3),
    },
  }),
);

function Charts() {
  const classes = useStyles();

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className={fixedHeightPaper}>
          <Typography
            className={classes.chartTitle}
            component="h2"
            variant="h6"
            color="primary"
            gutterBottom>
            Cumplimiento de entrega del laboratorio
          </Typography>

          <LaboratoryCompliancePercentaje />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={fixedHeightPaper}>
          <Typography
            className={classes.chartTitle}
            component="h2"
            variant="h6"
            color="primary"
            gutterBottom>
            Días promedio de retraso en el laboratorio
          </Typography>
          <ChartLaboratoryAverageLate />
        </Paper>
      </Grid>
    </Grid>
  );
}

const type_comment = StatisticCommentType.LABORATORIO;

const query_selector = (state: RootState) => ({
  filter_year: state.statistics.ui.filter_year,
  comments: state_get_comments(state, type_comment),
  edit_mode: get_edit_mode(state),
  token_verify_roles: token_verify_roles(state),
});

export default function Statistics() {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useTypedDispatch();
  const redux_state = useSelector(query_selector);

  const edit_mode =
    redux_state.edit_mode &&
    redux_state.token_verify_roles(LISTA_ROLES_CREAR_EDITAR);

  React.useEffect(() => {
    dispatch(get_comments(type_comment));
    dispatch(get_orders());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redux_state.filter_year]);

  const handle_save_comments = (value: string) => {
    dispatch(save_comments(type_comment, value));
  };

  return (
    <>
      <Grid item container spacing={3}>
        <Grid item xs={12} md={8}>
          <Charts />
        </Grid>

        <Grid item xs={12} md={4}>
          <CardComments
            title="Comentarios"
            height={2 * paper_chart_heigh + theme.spacing(3)}
            edit_mode={edit_mode}
            value={redux_state.comments}
            on_save={handle_save_comments}
          />
        </Grid>

        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Orders />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
