import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import store from 'redux/store';
import * as paths from 'config/paths';
import Login from 'views/Auth/Login';
import ProtectedRoute from 'containers/auth/ProtectedRoute';
import Error404 from 'views/Errores/404/404';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Admin from 'layouts/Admin/Admin';
import { ThemeProvider } from '@material-ui/core';
import theme from 'config/themes';

const hist = createBrowserHistory();

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      {/* <React.StrictMode> */}
      <Router history={hist}>
        <Switch>
          <Route path={paths.LOGIN_PATH} component={Login} exact />
          <ProtectedRoute path={paths.MAIN_PATH} component={Admin} />
          <ProtectedRoute path="*" component={Error404} />
        </Switch>
      </Router>
      {/* </React.StrictMode> */}
    </ThemeProvider>
  </Provider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
