import React from 'react';
import { Link } from 'react-router-dom';
import { MAIN_PATH } from 'config/paths';
import Image from 'assets/img/rob.png';
// import './404.css';

function Erorr404() {
  return (
    <>
      <div className="iso404Content">
        <h1>404</h1>
        <h3>Parece que estas perdido</h3>
        <p>La página que estas buscando no existe o ha sido movida</p>
        <button type="button">
          <Link to={MAIN_PATH}>Ir al inicio</Link>
        </button>
      </div>

      <div className="iso404Artwork">
        <img alt="#" src={Image} />
      </div>
    </>
  );
}

export default Erorr404;
