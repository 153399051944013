import jwt_decode from 'jwt-decode';
import * as Api from 'api/api';
import { axiosInstance } from 'api/api';
import { ACCESS_TOKEN_LIFETIME, TIME_TO_REFRESH_TOKEN } from 'config/config';
import { RefreshToken } from 'utilities/auth';
import {
  ActionSetLoading,
  ActionSetLogin,
  ActionSetLoginError,
  ActionSetRefreshTokenError,
  action_types,
} from './types';
import { Token } from 'api/types';
import { AppThunk } from 'redux/store';
import { seconds_to_milliseconds } from 'utilities/general';
import { reset_app } from 'redux/reducers';

///////////////////////////////////////////////////////////////////////////////
// Action Creattors
///////////////////////////////////////////////////////////////////////////////

const set_loading = (value: boolean): ActionSetLoading => ({
  type: action_types.SET_LOADING,
  value,
});

const set_login = (data: any): ActionSetLogin => {
  let token: Token | null = null;
  if (data) {
    token = jwt_decode(data.access);

    axiosInstance.defaults.headers['Authorization'] = 'JWT ' + data.access;
    RefreshToken.guardar(data.refresh);
  }

  return { type: action_types.SET_LOGIN, token };
};

const set_login_error = (error: boolean): ActionSetLoginError => ({
  type: action_types.SET_LOGIN_ERROR,
  error,
});

const set_refresh_token_error = (
  error: FixLater,
): ActionSetRefreshTokenError => ({
  type: action_types.SET_REFRESH_TOKEN_ERROR,
  error,
});

///////////////////////////////////////////////////////////////////////////////
// Redux thunk
///////////////////////////////////////////////////////////////////////////////

const login = (username: string, password: string): AppThunk => (dispatch) => {
  dispatch(set_loading(true));

  Api.obtener_token(username, password)
    .then((datos) => {
      dispatch(set_login(datos));

      setTimeout(() => {
        dispatch(refrescar_token());
      }, seconds_to_milliseconds(TIME_TO_REFRESH_TOKEN));
    })
    .catch((error) => {
      dispatch(set_login_error(error));
    })
    .finally(() => {
      dispatch(set_loading(false));
    });
};

const refrescar_token = (): AppThunk => (dispatch) => {
  const refresh_token = RefreshToken.obtener();

  if (!refresh_token) {
    dispatch(set_refresh_token_error({}));
    return;
  }

  const timeout = seconds_to_milliseconds(
    ACCESS_TOKEN_LIFETIME - TIME_TO_REFRESH_TOKEN - 10,
  );

  Api.refrescar_token(refresh_token, timeout)
    .then((datos) => {
      dispatch(set_login(datos));
      dispatch(set_refresh_token_error(null));

      setTimeout(() => {
        dispatch(refrescar_token());
      }, seconds_to_milliseconds(TIME_TO_REFRESH_TOKEN));
    })
    .catch((error) => {
      // Depende el tipo de error, se podria reintentar refrescar el token
      // Se puede intentar varias veces hasta que el token de acceso sea invalido
      // NOTA: Puede que el token no refresque debido a que fue invalidado en el servidor
      dispatch(set_login(null));
      dispatch(set_refresh_token_error(error));
    });
};

const logout = (): AppThunk => (dispatch) => {
  // Posteriormente se debe generar acciones en el back-end para invalidar
  // el token de acceso y el token de refrescar
  axiosInstance.defaults.headers['Authorization'] = '';
  RefreshToken.borrar();

  dispatch(reset_app());
};

export { action_types as acciones, login, refrescar_token, logout };
