import { EstadoOrdenLaboratorio } from 'api/consts';
import { NormalizedModelOrdenServicio } from 'api/types';
import ChartAverageLateDays from 'components/charts/ChartAverageLateDays';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import { Months, TimeDelta } from 'utilities/date';
import { round } from 'utilities/general';

function get_data(orders: NormalizedModelOrdenServicio) {
  const accu: Record<
    number,
    { name: string; sum: number; counter: number }
  > = {};

  for (const id of orders.ids) {
    const estado = orders.por_id[id].calc.estado_orden_laboratorio;
    const {
      desviacion_duracion_orden_laboratorio,
      fecha_programada_salida_laboratorio,
    } = orders.por_id[id].calc;

    if (
      !fecha_programada_salida_laboratorio ||
      !desviacion_duracion_orden_laboratorio
    )
      continue;

    const date = new Date(fecha_programada_salida_laboratorio);
    const month = date.getMonth();

    if (accu[month] === undefined) {
      accu[month] = {
        name: Months[month],
        sum: 0,
        counter: 0,
      };
    }

    if (estado === EstadoOrdenLaboratorio.TERMINADO_ATRASADO) {
      const timedelta = new TimeDelta(desviacion_duracion_orden_laboratorio);
      accu[month].sum += timedelta.days;
      accu[month].counter += 1;
    }
  }

  const data = Object.values(accu).map((elem) => {
    return {
      name: elem.name,
      days: elem.counter ? round(elem.sum / elem.counter, 1) : 0,
    };
  });

  return data;
}

export default function ChartLaboratoryAverageLate() {
  const orders = useSelector(
    (state: RootState) => state.statistics.data.orders,
  );

  if (!orders) return <></>;

  return <ChartAverageLateDays data={get_data(orders)} />;
}
