import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardProps,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { ModelMuestra } from 'api/types';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';

const useStyles = makeStyles(
  createStyles({
    subTitle: {
      fontWeight: 'bold',
    },
  }),
);

const query_selector = (state: RootState) => ({
  tipo_muestras: state.global.data.tipo_muestras,
  muestras_colores: state.global.data.muestras_colores,
  muestra_cantidad_unidades: state.global.data.muestra_cantidad_unidades,
});

interface Props extends CardProps {
  sample: ModelMuestra;
}

export default function CardSample(props: Props) {
  const classes = useStyles();
  const { sample, ...others_props } = props;
  const {
    tipo_muestras,
    muestras_colores,
    muestra_cantidad_unidades,
  } = useSelector(query_selector);

  return (
    <Card {...others_props}>
      <CardHeader
        title={sample.tipo && tipo_muestras.por_id[sample.tipo].nombre}
      />

      <CardContent>
        <>
          <div>
            <span className={classes.subTitle}>Ficho: </span>
            {sample.ficho}
            <br />
            <span className={classes.subTitle}>Color: </span>
            {sample.color && muestras_colores.por_id[sample.color].nombre}
            <br />
            <span className={classes.subTitle}>Fuente: </span>
            {sample.fuente}
            <br />
            <span className={classes.subTitle}>Temperatura: </span>
            {sample.temperatura}
            <br />
            <span className={classes.subTitle}>Cantidad: </span>
            {sample.cantidad}{' '}
            {sample.cantidad_unidad &&
              muestra_cantidad_unidades.por_id[sample.cantidad_unidad].nombre}
            <br />
          </div>
        </>
      </CardContent>

      <CardActions></CardActions>
    </Card>
  );
}
