import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import esLocale from 'date-fns/locale/es';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import { set_year_filter } from 'redux/statistics/actions';
import { useTypedDispatch } from 'redux/store';

export default function FilterYear() {
  const dispatch = useTypedDispatch();
  const year = useSelector(
    (state: RootState) => state.statistics.ui.filter_year,
  );

  const handle_change = (date: Date | null) => {
    if (date) dispatch(set_year_filter(date));
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
      <DatePicker
        variant="inline"
        views={['year']}
        label="Año"
        value={year}
        onChange={handle_change}
        autoOk
        minDate={new Date(2015, 0, 1)}
        disableFuture
        inputVariant="outlined"
      />
    </MuiPickersUtilsProvider>
  );
}
