import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: 'auto',
      [theme.breakpoints.up('md')]: {
        width: 800,
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
      },
      backgroundColor: theme.palette.background.paper,
    },
    form: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
  });

export default styles;
