import DateFnsUtils from '@date-io/date-fns';
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import AsyncClientAutoComplete from 'containers/FormFields/AsyncClientAutoComplete';
import esLocale from 'date-fns/locale/es';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  definir_filtro_cliente,
  definir_filtro_estado_laboratorio,
  definir_filtro_estado_total,
  definir_filtro_fecha_final,
  definir_filtro_fecha_inicial,
  definir_filtro_id_orden,
  definir_filtro_tipo_muestra,
} from 'redux/orden_servicio/actions';
import { query_filters } from 'redux/orden_servicio/reducers';
import { RootState } from 'redux/reducers';
import { useTypedDispatch } from 'redux/store';
import {
  form_number_to_string,
  form_string_to_number,
} from 'utilities/general';

const query_selector = (state: RootState) => ({
  estados_orden_laboratorio: state.global.data.estados_orden_laboratorio,
  estados_orden_total: state.global.data.estados_orden_total,
  tipo_muestras: state.global.data.tipo_muestras,
});

export default function Filters() {
  const filters = useSelector(query_filters);

  const {
    estados_orden_laboratorio,
    estados_orden_total,
    tipo_muestras,
  } = useSelector(query_selector);
  const dispatch = useTypedDispatch();

  return (
    <Card>
      <CardHeader title="Filtros" />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              type="number"
              label="Id de orden"
              value={form_number_to_string(filters.filtro_id_orden)}
              onChange={(event) => {
                dispatch(
                  definir_filtro_id_orden(
                    form_string_to_number(event.target.value),
                  ),
                );
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              value={filters.filtro_tipo_muestra}
              onChange={(event, valor) => {
                dispatch(definir_filtro_tipo_muestra(valor));
              }}
              options={tipo_muestras.ids}
              getOptionLabel={(id) => tipo_muestras.por_id[id].nombre}
              renderInput={(params) => (
                <TextField {...params} label="Tipo de muestra" />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <AsyncClientAutoComplete
              client_id={filters.filtro_cliente}
              on_change={(event, valor) => {
                dispatch(definir_filtro_cliente(valor));
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <Autocomplete
              value={filters.filtro_estado_laboratorio}
              onChange={(event, valor) => {
                dispatch(definir_filtro_estado_laboratorio(valor));
              }}
              options={estados_orden_laboratorio.ids}
              getOptionLabel={(id) =>
                estados_orden_laboratorio.por_id[id].nombre
              }
              renderInput={(params) => (
                <TextField {...params} label="Estado laboratorio" />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              value={filters.filtro_estado_total}
              onChange={(event, valor) => {
                dispatch(definir_filtro_estado_total(valor));
              }}
              options={estados_orden_total.ids}
              getOptionLabel={(id) => estados_orden_total.por_id[id].nombre}
              renderInput={(params) => (
                <TextField {...params} label="Estado total" />
              )}
            />
          </Grid>

          <Grid item xs={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <DatePicker
                label="Fecha inicial"
                format="dd/MM/yyyy"
                value={filters.filtro_fecha_inicial}
                onChange={(fecha) => {
                  dispatch(definir_filtro_fecha_inicial(fecha));
                }}
                autoOk
                cancelLabel="Cancelar"
                clearable={true}
                clearLabel="Limpiar"
                style={{ width: '100%' }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <DatePicker
                label="Fecha final"
                format="dd/MM/yyyy"
                value={filters.filtro_fecha_final}
                onChange={(fecha) => {
                  dispatch(definir_filtro_fecha_final(fecha));
                }}
                autoOk
                cancelLabel="Cancelar"
                clearable={true}
                clearLabel="Limpiar"
                style={{ width: '100%' }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
