import { useTheme } from '@material-ui/core';
import React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

interface ChartCompliancePercentageProps {
  data: Array<{
    name: string;
    on_time: number;
    late: number;
    on_progress: number;
  }>;
  on_time_label?: string;
  late_label?: string;
  on_progress_label?: string;
}

export default function ChartCompliancePercentage(
  props: ChartCompliancePercentageProps,
) {
  const theme = useTheme();
  const {
    data,
    late_label = 'Retrasado',
    on_time_label = 'A tiempo',
    on_progress_label = 'Sin terminar',
  } = props;

  return (
    <ResponsiveContainer>
      <BarChart
        data={data}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis domain={[0, 100]} unit="%" />
        <Tooltip />
        <Legend />
        <Bar
          dataKey="on_time"
          unit="%"
          name={on_time_label}
          stackId="1"
          fill={theme.palette.success.light}
        />
        <Bar
          dataKey="late"
          unit="%"
          name={late_label}
          stackId="1"
          fill={theme.palette.error.light}
        />
        <Bar
          dataKey="on_progress"
          unit="%"
          name={on_progress_label}
          stackId="1"
          fill={theme.palette.warning.light}
        />
      </BarChart>
    </ResponsiveContainer>
  );
}
