import {
  createStyles,
  IconButton,
  ListItemSecondaryAction,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import {
  ModelMuestra,
  NormalizedModelMuestraCantidadUnidad,
  NormalizedModelTipoColor,
  NormalizedModelTipoMuestra,
} from 'api/types';
import * as paths from 'config/paths';
import { LISTA_ROLES_CREAR_EDITAR } from 'config/user_groups';
import ProtectedComponent from 'containers/auth/ProtectedComponent';
import React from 'react';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles(
  createStyles({
    subTitle: {
      fontWeight: 'bold',
    },
  }),
);

interface Props {
  muestra: ModelMuestra;
  tipo_muestras: NormalizedModelTipoMuestra;
  muestras_colores: NormalizedModelTipoColor;
  muestra_cantidad_unidades: NormalizedModelMuestraCantidadUnidad;
  edit_mode: boolean;
  handle_open_edit_sample: (muestra: ModelMuestra) => (event: any) => void;
  handle_open_delete_sample: (id: number) => (event: any) => void;
}

export default function ItemMuestra(props: Props) {
  const classes = useStyles();
  const {
    muestra,
    tipo_muestras,
    muestras_colores,
    muestra_cantidad_unidades,
    edit_mode,
    handle_open_edit_sample,
    handle_open_delete_sample,
  } = props;

  return (
    <NavLink
      key={muestra.id}
      to={`${paths.MUESTRA_PATH}/${muestra.id}`}
      style={{ color: 'inherit', textDecoration: 'inherit' }}>
      <ListItem alignItems="center" button>
        <ListItemText
          primary={muestra.tipo && tipo_muestras.por_id[muestra.tipo].nombre}
          secondaryTypographyProps={{ component: 'span' }}
          secondary={
            <div>
              <span className={classes.subTitle}>Ficho: </span>
              {muestra.ficho}
              <br />
              <span className={classes.subTitle}>Color: </span>
              {muestra.color && muestras_colores.por_id[muestra.color].nombre}
              <br />
              <span className={classes.subTitle}>Fuente: </span>
              {muestra.fuente}
              <br />
              <span className={classes.subTitle}>Temperatura: </span>
              {muestra.temperatura}
              <br />
              <span className={classes.subTitle}>Cantidad: </span>
              {muestra.cantidad}{' '}
              {muestra.cantidad_unidad &&
                muestra_cantidad_unidades.por_id[muestra.cantidad_unidad]
                  .nombre}
              <br />
            </div>
          }
        />

        {edit_mode && (
          <ProtectedComponent roles={LISTA_ROLES_CREAR_EDITAR}>
            <ListItemSecondaryAction>
              <Tooltip title="Editar">
                <IconButton onClick={handle_open_edit_sample(muestra)}>
                  <EditIcon color="primary" />
                </IconButton>
              </Tooltip>

              <Tooltip title="Eliminar">
                <IconButton onClick={handle_open_delete_sample(muestra.id)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </ListItemSecondaryAction>
          </ProtectedComponent>
        )}
      </ListItem>
    </NavLink>
  );
}
