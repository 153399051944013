import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { ModelOrdenServicioEdit } from 'api/types';
import TabPanel, { a11yProps } from 'components/TabPanel';
import React from 'react';
import Tab1 from './Tab1';
import Tab2 from './Tab2';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 'auto',
      [theme.breakpoints.up('md')]: {
        width: 800,
        height: 465,
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
      },
      backgroundColor: theme.palette.background.paper,
    },
    tabPanel: {
      marginTop: theme.spacing(3),
    },
  }),
);

enum EnumTabs {
  ORDEN_SERVICIO,
  ESTADO_TECNICO,
}

interface Props {
  datos: ModelOrdenServicioEdit;
  handle_change: (name: keyof ModelOrdenServicioEdit, value: any) => any;
}

export default function Form(props: Props) {
  const classes = useStyles();
  const { datos, handle_change } = props;

  const [tab, set_tab] = React.useState(EnumTabs.ORDEN_SERVICIO);
  const handle_change_tab = (
    event: React.ChangeEvent<{}>,
    new_tab: EnumTabs,
  ) => {
    set_tab(new_tab);
  };

  return (
    <div className={classes.root}>
      <Tabs
        value={tab}
        onChange={handle_change_tab}
        aria-label="wrapped label tabs example">
        <Tab
          value={EnumTabs.ORDEN_SERVICIO}
          label="Orden de servicio"
          wrapped
          {...a11yProps(EnumTabs.ORDEN_SERVICIO)}
        />
        <Tab
          value={EnumTabs.ESTADO_TECNICO}
          label="Estado técnico"
          {...a11yProps(EnumTabs.ESTADO_TECNICO)}
        />
      </Tabs>

      <TabPanel
        className={classes.tabPanel}
        value={tab}
        index={EnumTabs.ORDEN_SERVICIO}>
        <Tab1 datos={datos} handle_change={handle_change} />
      </TabPanel>

      <TabPanel
        className={classes.tabPanel}
        value={tab}
        index={EnumTabs.ESTADO_TECNICO}>
        <Tab2 datos={datos} handle_change={handle_change} />
      </TabPanel>
    </div>
  );
}
