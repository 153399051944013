import axios, { AxiosResponse } from 'axios';
import { API_BASE_URL } from 'config/config';
import { StatisticCommentType } from './consts';
import {
  ModelCliente,
  ModelOrdenServicioEdit,
  NormalizedModelEspecificacion,
  NormalizedModelEstadoOrdenLaboratorio,
  NormalizedModelEstadoOrdenTotal,
  NormalizedModelEstadoTecnico,
  NormalizedModelGrupoTipoEnsayo,
  NormalizedModelMuestraCantidadUnidad,
  NormalizedModelOrdenTomadaEn,
  NormalizedModelTipoColor,
  NormalizedModelTipoEnsayo,
  NormalizedModelTipoMuestra,
} from './types';
import urls from './urls';

export const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  // There should be a timeout and handle the timeout error
  // timeout: 60000,
  headers: {
    Authorization: 'JWT ' + localStorage.getItem('access_token'),
    'Content-Type': 'application/json',
    accept: 'application/json',
  },
});

export async function obtener_token(username: string, password: string) {
  const response = await axiosInstance.post(urls.TOKEN_OBTAIN, {
    username,
    password,
  });
  return response.data;
}

export async function refrescar_token(
  refresh_token: string | null,
  timeout: number,
) {
  const response = await axiosInstance.post(
    urls.TOKEN_REFRESH,
    { refresh: refresh_token },
    { timeout: timeout },
  );
  return response.data;
}

export function obtener_clientes(params: ModelCliente) {
  return axiosInstance.get(urls.CLIENTES, { params });
}

export function obtener_obras() {
  return axiosInstance.get(urls.OBRAS);
}

export function obtener_tipo_ensayos() {
  return axiosInstance.get<NormalizedModelTipoEnsayo>(urls.TIPO_ENSAYOS);
}

export function obtener_grupos_tipo_ensayos() {
  return axiosInstance.get<NormalizedModelGrupoTipoEnsayo>(
    urls.GRUPOS_TIPO_ENSAYOS,
  );
}

export function obtener_tipo_muestras() {
  return axiosInstance.get<NormalizedModelTipoMuestra>(urls.TIPO_MUESTRAS);
}

export function obtener_muestras_colores() {
  return axiosInstance.get<NormalizedModelTipoColor>(urls.MUESTRA_COLORES);
}

export function obtener_orden_tomada_en() {
  return axiosInstance.get<NormalizedModelOrdenTomadaEn>(urls.ORDEN_TOMADA_EN);
}

export function obtener_muestra_cantidad_unidades() {
  return axiosInstance.get<NormalizedModelMuestraCantidadUnidad>(
    urls.MUESTRA_CANTIDAD_UNIDADES,
  );
}

export function obtener_especificaciones() {
  return axiosInstance.get<NormalizedModelEspecificacion>(
    urls.ESPECIFICACIONES,
  );
}

export function obtener_sedes() {
  return axiosInstance.get(urls.SEDES);
}

export function obtener_estados_tecnicos() {
  return axiosInstance.get<NormalizedModelEstadoTecnico>(urls.ESTADOS_TECNICOS);
}

export function obtener_estados_orden_laboratorio() {
  return axiosInstance.get<NormalizedModelEstadoOrdenLaboratorio>(
    urls.ESTADOS_ORDEN_LABORATORIO,
  );
}

export function obtener_estados_orden_total() {
  return axiosInstance.get<NormalizedModelEstadoOrdenTotal>(
    urls.ESTADOS_ORDEN_TOTAL,
  );
}

export function get_media_file(url: string) {
  return axiosInstance.get(url, { responseType: 'blob' });
}

class Base {
  url = '';
  url_detalle = (url: string, id: number) =>
    url.endsWith('/') ? `${url}${id}/` : `${url}/${id}/`;

  listar = (filtros: FixLater) => {
    return axiosInstance.get(this.url, { params: filtros });
  };

  detalle = (id: number) => {
    return axiosInstance.get(this.url_detalle(this.url, id));
  };

  crear = (datos: FixLater) => {
    return axiosInstance.post(this.url, datos);
  };

  actualizar = (id: number, datos: FixLater) => {
    // return axiosInstance.put(this.url_detalle(this.url, id), datos);
    return axiosInstance.patch(this.url_detalle(this.url, id), datos);
  };

  eliminar = (id: number) => {
    return axiosInstance.delete(this.url_detalle(this.url, id));
  };
}

class _StatisticComment {
  detail = (year: number, type_comment: StatisticCommentType) => {
    return axiosInstance.get(urls.ESTADISTICAS_COMENTARIOS(year, type_comment));
  };

  update = (
    year: number,
    type_comment: StatisticCommentType,
    comments: string,
  ) => {
    return axiosInstance.patch(
      urls.ESTADISTICAS_COMENTARIOS(year, type_comment),
      { comentario: comments },
    );
  };
}

class _Obra extends Base {
  url = urls.OBRAS;
}

class _Cliente extends Base {
  url = urls.CLIENTES;
}

class _OrdenServicio extends Base {
  url = urls.ORDENES_SERVICIOS;

  actualizar_informe = (informe: File | string | null) => (
    response: AxiosResponse<any>,
  ) => {
    if (typeof informe === 'string') return response;

    let form_data = new FormData();
    form_data.append('informe', informe ? informe : new File([], ''));
    return axiosInstance.patch(
      this.url_detalle(this.url, response.data.id),
      form_data,
    );
  };

  crear = (datos: ModelOrdenServicioEdit) => {
    const { informe, ...otros } = datos;
    return axiosInstance
      .post(this.url, otros)
      .then(this.actualizar_informe(informe));
  };

  actualizar = (id: number, datos: ModelOrdenServicioEdit) => {
    const { informe, ...otros } = datos;
    return axiosInstance
      .patch(this.url_detalle(this.url, id), otros)
      .then(this.actualizar_informe(informe));
  };
}

class _Muestra extends Base {
  url = urls.MUESTRAS;
}

class _EnsayoPorMuestra extends Base {
  url = urls.ENSAYOS_POR_MUESTRA;
}

export const Obra = new _Obra();
export const Cliente = new _Cliente();
export const OrdenServicio = new _OrdenServicio();
export const Muestra = new _Muestra();
export const EnsayoPorMuestra = new _EnsayoPorMuestra();
export const StatisticComment = new _StatisticComment();
