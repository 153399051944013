import { Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { set_order_breadcrumbs } from 'redux/global/actions';
import {
  obtener_ensayos_por_muestra,
  obtener_muestra_detalle,
} from 'redux/orden_servicio/actions';
import { RootState } from 'redux/reducers';
import { useTypedDispatch } from 'redux/store';
import CardSample from './CardSample';
import CardTests from './CardTests';

const query_selector = (state: RootState) => ({
  muestra: state.orden_servicio.datos.muestra,
  ensayos_por_muestra: state.orden_servicio.datos.ensayos_por_muestra,
});

interface Props {
  match: {
    params: {
      id: number;
    };
  };
}

export default function MuestraDetalle(props: Props) {
  const dispatch = useTypedDispatch();
  const { muestra, ensayos_por_muestra } = useSelector(query_selector);

  useEffect(() => {
    const { id } = props.match.params;
    dispatch(obtener_muestra_detalle(id));
    dispatch(obtener_ensayos_por_muestra(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (muestra)
      dispatch(set_order_breadcrumbs(true, muestra.orden_servicio, muestra.id));

    return () => {
      dispatch(set_order_breadcrumbs(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [muestra]);

  if (!muestra || !ensayos_por_muestra) {
    // Deberia retornar un componente de cargando
    return <></>;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={7}>
        <CardTests
          sample={muestra}
          tests={ensayos_por_muestra}
          style={{ height: '100%' }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={5}>
        <CardSample sample={muestra} />
      </Grid>
    </Grid>
  );
}
