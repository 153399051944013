import {
  createStyles,
  IconButton,
  ListItemSecondaryAction,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { ModelEnsayoPorMuestra, NormalizedModelTipoEnsayo } from 'api/types';
import { LABORATORISTA, LISTA_ROLES_CREAR_EDITAR } from 'config/user_groups';
import ProtectedComponent from 'containers/auth/ProtectedComponent';
import React from 'react';
import { formatear_fecha } from 'utilities/general';

const useStyles = makeStyles(
  createStyles({
    subTitle: {
      fontWeight: 'bold',
    },
  }),
);

interface Props {
  test: ModelEnsayoPorMuestra;
  set_edit_test: (test: ModelEnsayoPorMuestra) => void;
  set_delete_test: (id: number) => void;
  tipo_ensayos: NormalizedModelTipoEnsayo;
  edit_mode: boolean;
}

export default function ItemTest(props: Props) {
  const classes = useStyles();
  const {
    test,
    set_edit_test,
    set_delete_test,
    tipo_ensayos,
    edit_mode,
  } = props;
  return (
    <ListItem key={test.id} alignItems="flex-start">
      <ListItemText
        primary={`${tipo_ensayos.por_id[test.tipo_ensayo].codigo}: ${
          tipo_ensayos.por_id[test.tipo_ensayo].nombre
        }`}
        secondaryTypographyProps={{ component: 'span' }}
        secondary={
          <>
            <div>
              <span className={classes.subTitle}>
                Fecha máxima programada inicio:{' '}
              </span>
              {test.fecha_maxima_programada_inicio &&
                formatear_fecha(test.fecha_maxima_programada_inicio)}
            </div>
            <div>
              <span className={classes.subTitle}>Fecha real inicio: </span>
              {test.fecha_real_inicio &&
                formatear_fecha(test.fecha_real_inicio)}
            </div>

            <div>
              <span className={classes.subTitle}>
                Fecha máxima programada fin:{' '}
              </span>
              {test.fecha_maxima_programada_fin &&
                formatear_fecha(test.fecha_maxima_programada_fin)}
            </div>
            <div>
              <span className={classes.subTitle}>Fecha real fin: </span>
              {test.fecha_real_fin && formatear_fecha(test.fecha_real_fin)}
            </div>
            <div>
              <span className={classes.subTitle}>Usuario: </span>
              {test.usuario && test.usuario}
            </div>
          </>
        }
      />

      {edit_mode && (
        <ListItemSecondaryAction>
          <ProtectedComponent roles={[LABORATORISTA]}>
            <Tooltip title="Editar">
              <IconButton onClick={() => set_edit_test(test)}>
                <EditIcon color="primary" />
              </IconButton>
            </Tooltip>
          </ProtectedComponent>

          <ProtectedComponent roles={LISTA_ROLES_CREAR_EDITAR}>
            <Tooltip title="Eliminar">
              <IconButton onClick={() => set_delete_test(test.id)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </ProtectedComponent>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
}
