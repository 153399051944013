import {
  Card,
  CardContent,
  CardHeader,
  CardProps,
  Divider,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import List from '@material-ui/core/List';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import {
  ModelMuestra,
  ModelOrdenServicioListar,
  NormalizedModelMuestra,
} from 'api/types';
import AlertDialog from 'components/Dialogs/AlertDialog';
import { LISTA_ROLES_CREAR_EDITAR } from 'config/user_groups';
import ProtectedComponent from 'containers/auth/ProtectedComponent';
import DialogCrearmuestra from 'containers/Dialogs/DialogMuestra/DialogCrearMuestra';
import DialogEditSample from 'containers/Dialogs/DialogMuestra/DialogEditMuestra';
import React from 'react';
import { useSelector } from 'react-redux';
import { get_edit_mode } from 'redux/global/reducers';
import { eliminar_muestra } from 'redux/orden_servicio/actions';
import { RootState } from 'redux/reducers';
import { useTypedDispatch } from 'redux/store';
import ItemMuestra from './ItemMuestra';

const query_selector = (state: RootState) => ({
  muestras_colores: state.global.data.muestras_colores,
  tipo_muestras: state.global.data.tipo_muestras,
  muestra_cantidad_unidades: state.global.data.muestra_cantidad_unidades,
  edit_mode: get_edit_mode(state),
});

interface Props extends CardProps {
  orden_servicio: ModelOrdenServicioListar;
  muestras: NormalizedModelMuestra;
}

export default function CardMuestras(props: Props) {
  const redux_state = useSelector(query_selector);
  const dispatch = useTypedDispatch();

  const { orden_servicio, muestras, ...others_props } = props;

  // For add sample
  const [open_add_sample, set_open_add_sample] = React.useState(false);
  const handle_open_add_sample = () => {
    set_open_add_sample(true);
  };
  const handle_close_add_sample = () => {
    set_open_add_sample(false);
  };

  // For edit sample
  const [edit_sample, set_edit_sample] = React.useState<ModelMuestra | null>(
    null,
  );
  const handle_open_edit_sample = (sample: ModelMuestra) => (event: any) => {
    event.preventDefault();
    set_edit_sample(sample);
  };
  const handle_close_edit_sample = () => {
    set_edit_sample(null);
  };

  // For delete sample
  const [delete_sample, set_delete_sample] = React.useState<number | null>(
    null,
  );
  const handle_open_delete_sample = (sample_id: number) => (event: any) => {
    event.preventDefault();
    set_delete_sample(sample_id);
  };
  const handle_accept_delete_sample = () => {
    if (!delete_sample) return;
    dispatch(eliminar_muestra(delete_sample)).then(() => {
      set_delete_sample(null);
    });
  };
  const handle_cancel_delete_sample = () => {
    set_delete_sample(null);
  };

  return (
    <>
      <Card {...others_props}>
        <CardHeader
          title="Muestras"
          action={
            redux_state.edit_mode && (
              <ProtectedComponent roles={LISTA_ROLES_CREAR_EDITAR}>
                <Tooltip title="Crear muestra">
                  <IconButton onClick={handle_open_add_sample}>
                    <AddCircleIcon color="primary" style={{ fontSize: 35 }} />
                  </IconButton>
                </Tooltip>
              </ProtectedComponent>
            )
          }
        />

        <CardContent>
          <List>
            {muestras.ids &&
              muestras.ids.map((id, index, list) => (
                <div key={id}>
                  <ItemMuestra
                    muestra={muestras.por_id[id]}
                    tipo_muestras={redux_state.tipo_muestras}
                    muestras_colores={redux_state.muestras_colores}
                    muestra_cantidad_unidades={
                      redux_state.muestra_cantidad_unidades
                    }
                    edit_mode={redux_state.edit_mode}
                    handle_open_edit_sample={handle_open_edit_sample}
                    handle_open_delete_sample={handle_open_delete_sample}
                  />
                  {index < list.length - 1 && <Divider />}
                </div>
              ))}
          </List>
        </CardContent>
      </Card>

      <DialogCrearmuestra
        orden_servicio={orden_servicio.id}
        open={open_add_sample}
        on_cancel={handle_close_add_sample}
      />

      {edit_sample && (
        <DialogEditSample
          open={Boolean(edit_sample)}
          muestra={edit_sample}
          on_cancel={handle_close_edit_sample}
        />
      )}

      <AlertDialog
        open={delete_sample !== null}
        onAccept={handle_accept_delete_sample}
        onCancel={handle_cancel_delete_sample}
        title="¿Esta seguro?"
        content="¿Desea eliminar esta muestra?"
      />
    </>
  );
}
