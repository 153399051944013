import {
  createStyles,
  IconButton,
  ListItemSecondaryAction,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { ModelOrdenServicioListar } from 'api/types';
import * as paths from 'config/paths';
import { LISTA_ROLES_CREAR_EDITAR } from 'config/user_groups';
import ProtectedComponent from 'containers/auth/ProtectedComponent';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTypedSelector } from 'redux/reducers';
import { formatear_fecha } from 'utilities/general';
import { get_order_id } from 'utilities/order_formaters';

const useStyles = makeStyles(
  createStyles({
    subTitle: {
      fontWeight: 'bold',
    },
  }),
);

interface Props {
  order: ModelOrdenServicioListar;
  handle_open_edit_order: (
    order: ModelOrdenServicioListar,
  ) => (event: any) => void;
  handle_open_delete_order: (order: number) => (event: any) => void;
  edit_mode: boolean;
}

export default function ItemOrder(props: Props) {
  const classes = useStyles();
  const {
    order,
    handle_open_edit_order,
    handle_open_delete_order,
    edit_mode,
  } = props;

  const sedes = useTypedSelector((state) => state.global.data.sedes);

  return (
    <NavLink
      key={order.id}
      to={`${paths.ORDEN_SERVICIO_PATH}/${order.id}`}
      style={{ color: 'inherit', textDecoration: 'inherit' }}>
      <ListItem alignItems="flex-start" button>
        <ListItemText
          primary={`Id de orden: ${get_order_id(order)}`}
          secondaryTypographyProps={{ component: 'span' }}
          secondary={
            <>
              {order.numero_orden && (
                <div style={{ fontStyle: 'italic' }}>
                  <span>Número de orden: </span>
                  {order.numero_orden}
                </div>
              )}

              <div>
                <span className={classes.subTitle}>Fecha: </span>
                {formatear_fecha(order.fecha_recibido, false)}
              </div>
              <div>
                <span className={classes.subTitle}>Sede: </span>
                {sedes.por_id[order.sede].nombre}
              </div>
              <div>
                <span className={classes.subTitle}>Ubicación muestras: </span>
                {sedes.por_id[order.ubicacion_muestras].nombre}
              </div>
              <div>
                <span className={classes.subTitle}>Cliente: </span>
                {order.obra.cliente.nombre}
              </div>

              <div>
                <span className={classes.subTitle}>Obra: </span>
                {order.obra.nombre}
              </div>

              <div>
                <span className={classes.subTitle}>Código obra: </span>
                {order.obra.codigo}
              </div>
            </>
          }
        />

        {edit_mode && (
          <ProtectedComponent roles={LISTA_ROLES_CREAR_EDITAR}>
            <ListItemSecondaryAction>
              <Tooltip title="Editar">
                <IconButton onClick={handle_open_edit_order(order)}>
                  <EditIcon color="primary" />
                </IconButton>
              </Tooltip>

              <Tooltip title="Eliminar">
                <IconButton onClick={handle_open_delete_order(order.id)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </ListItemSecondaryAction>
          </ProtectedComponent>
        )}
      </ListItem>
    </NavLink>
  );
}
