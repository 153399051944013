import { LOGIN_PATH } from 'config/paths';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { logout } from 'redux/auth/actions';

export default function Logout() {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(logout());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Redirect to={LOGIN_PATH} />;
}
