import * as Api from 'api/api';
import * as paths from 'config/paths';
import React from 'react';
import { Redirect } from 'react-router-dom';
import FormMuestra from './FormMuestra';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ModelMuestra, ModelMuestraEdit } from 'api/types';
import { Button, DialogActions } from '@material-ui/core';

interface Props {
  open: boolean;
  orden_servicio: number;
  on_cancel: (event: any) => void;
}

export default function DialogCreateSample(props: Props) {
  const theme = useTheme();
  const full_screen = useMediaQuery(theme.breakpoints.down('sm'));
  const { open, on_cancel } = props;

  const initial_data: ModelMuestraEdit = {
    orden_servicio: props.orden_servicio,
    tipo: null,
    ficho: null,
    color: null,
    fuente: '',
    temperatura: null,
    cantidad: 1,
    cantidad_unidad: null,
  } as const;

  // Hooks
  const [sample_saved, set_sample_saved] = React.useState<ModelMuestra | null>(
    null,
  );

  const handle_submit = (data: ModelMuestra) => {
    Api.Muestra.crear(data)
      .then((response) => {
        set_sample_saved(response.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  if (sample_saved)
    return <Redirect to={paths.muestra_detalle(sample_saved.id)} />;

  const form_id = 'form-create-sample';

  return (
    <Dialog
      fullScreen={full_screen}
      open={open}
      onClose={on_cancel}
      maxWidth={false}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">Crear muestra</DialogTitle>

      <DialogContent>
        <FormMuestra
          form_id={form_id}
          datos_iniciales={initial_data}
          on_submit={handle_submit}
          on_cancel={on_cancel}
        />
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={on_cancel}>
          Cancelar
        </Button>

        <Button
          variant="contained"
          color="primary"
          type="submit"
          form={form_id}>
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
