import urls from 'api/urls';
import { API_BASE_URL } from 'config/config';
import { MAIN_PATH } from 'config/paths';
import React from 'react';
import { Redirect } from 'react-router-dom';

export default class Administracion extends React.Component {
  render() {
    return <Redirect to={MAIN_PATH} />;
  }

  componentDidMount() {
    window.open(API_BASE_URL + urls.DJANGO_ADMIN);
  }
}
