import { Normalized, ObjectWithId } from 'api/types';

/**
 * Omit some keys of a object
 */
export function omit(obj: any, omitKeys: readonly string[]) {
  let ans = { ...obj };
  for (const key of omitKeys) {
    delete ans[key];
  }
  return ans;
}

/**
 * @param _fecha Fecha con formato ISO 8601
 * @param corto true para formato corto, de lo contrario false
 */
export function formatear_fecha(_fecha: string, corto: boolean = true): string {
  const fecha = new Date(_fecha);
  const opciones = corto
    ? { day: 'numeric', month: 'short', year: 'numeric' }
    : { day: 'numeric', month: 'long', year: 'numeric' };

  if (corto)
    return fecha.toLocaleDateString('es-CO', opciones).replace(/de /g, '');

  return fecha.toLocaleDateString('es-CO', opciones);
}

/**
 * Return date with initial hour of day according to local time.
 * 0 hours, 0 minutes, 0 seconds, 0 milliseconds
 */
export function date_initial_hour_day(_date: Date) {
  let date = new Date(_date);
  date.setHours(0, 0, 0, 0);
  return date;
}

/**
 * Return date with final hour of day according to local time
 * 23 hours, 59 minutes, 59 seconds, 999 milliseconds
 */
export function date_final_hour_day(_date: Date) {
  let date = new Date(_date);
  date.setHours(23, 59, 59, 999);
  return date;
}

/**
 * Simple function to convert seconds to milliseconds
 */
export function seconds_to_milliseconds(seconds: number) {
  return seconds * 1000;
}

/**
 * Convenient function to convert string to numbers on forms
 *
 * If value is an empty string the result is null else parseInt that value
 */
export function form_string_to_number(value: string): number | null {
  return value === '' ? null : parseInt(value);
}

/**
 * Convenvenient function to convert numbers to string on forms
 *
 * if value is null the result is an empty string else toString that value
 */
export function form_number_to_string(value: number | null): string {
  return value === null ? '' : value.toString();
}

/**
 * Delete a value from an array
 */
export function array_delete_value<T>(array: readonly T[], value: T): T[] {
  return array.filter((item) => item !== value);
}

/**
 * Array intersection between two arrays
 */
export function array_intersection<T>(
  array1: readonly T[],
  array2: readonly T[],
): T[] {
  return array1.filter((x) => array2.includes(x));
}

/**
 * Difference between two arrays
 */
export function array_difference<T>(
  array1: readonly T[],
  array2: readonly T[],
): T[] {
  return array1.filter((x) => !array2.includes(x));
}

/**
 * symmetric difference between two arrays
 */
export function array_symmetric_difference<T>(
  array1: readonly T[],
  array2: readonly T[],
): T[] {
  return array1
    .filter((x) => !array2.includes(x))
    .concat(array2.filter((x) => !array1.includes(x)));
}

/**
 * When a sequence of calls of the returned function ends, the argument function is triggered.
 * The end of a sequence is defined by the wait parameter. If immediate is passed,
 * the argument function will be triggered at the beginning of the sequence instead of at the end.
 *
 * See: https://levelup.gitconnected.com/debounce-in-javascript-improve-your-applications-performance-5b01855e086
 *
 * @param func Function to call
 * @param wait Time in milliseconds to wait
 * @param immediate If true, func is called at the beginning of the wait
 */
export function debounce(func: any, wait: number, immediate: boolean) {
  let timeout: number | undefined = undefined;

  return function callableFunc(...args: string[]) {
    const later = () => {
      timeout = undefined;
      if (!immediate) func(...args);
    };

    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = window.setTimeout(later, wait);
    if (callNow) func(...args);
  };
}

/**
 * Get empty objetc with normalized structure
 */
export function get_empty_normalized_object<T>() {
  let ans: Normalized<T> = {
    ids: [],
    por_id: {},
  };
  return ans;
}

/**
 * Normalize list of objects by id, the field id is required and must be unique across all objects
 */
export function normalize_objects<T extends ObjectWithId>(
  list_objects: readonly T[],
) {
  let ans = get_empty_normalized_object<T>();

  for (const obj of list_objects) {
    ans.ids.push(obj.id);
    ans.por_id[obj.id] = obj;
  }
  return ans;
}

/**
 * Convert value to array
 */
export function to_array<T>(value: T | readonly T[]): T[] {
  return Array.isArray(value) ? [...value] : [value];
}

/**
 * Round a number to a especific number of digits
 */
export function round(number: number, digits: number = 0): number {
  return parseFloat(number.toFixed(digits));
}

export function percent_round(numbers: number[], digits: number): number[] {
  const sum = numbers.reduce((accu, elem) => accu + elem, 0);
  if (sum === 0) return numbers;

  let percent_sum = 0;

  let min_remain: null | number = null;
  let min_remain_index: null | number = null;

  let result = numbers.map((number, index) => {
    const percent = round((100 * number) / sum, digits);
    const remain = number - Math.floor(percent);

    if (min_remain === null) {
      min_remain = remain;
      min_remain_index = index;
    } else if (remain < min_remain) {
      min_remain = remain;
      min_remain_index = index;
    }

    percent_sum += percent;
    return percent;
  });

  // const diff = percent_sum - 100;
  // if (diff > 0 && min_remain_index !== null) {
  //   result[min_remain_index] -= diff;
  // }

  return result;
}
