import { Grid } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { set_order_breadcrumbs } from 'redux/global/actions';
import {
  obtener_muestras,
  obtener_orden_servicio_detalle,
} from 'redux/orden_servicio/actions';
import { RootState } from 'redux/reducers';
import { useTypedDispatch } from 'redux/store';
import CardCalc from './CardCalc';
import CardMuestras from './CardMuestras';
import CardOrden from './CardOrden';

const query_selector = (state: RootState) => ({
  orden_servicio: state.orden_servicio.datos.orden_servicio,
  muestras: state.orden_servicio.datos.muestras,
});

interface Props {
  match: {
    params: {
      id: number;
    };
  };
}

export default function Detalle(props: Props) {
  const { orden_servicio, muestras } = useSelector(query_selector);
  const dispatch = useTypedDispatch();

  React.useEffect(() => {
    const { id } = props.match.params;
    dispatch(obtener_orden_servicio_detalle(id));
    dispatch(obtener_muestras(id));
    dispatch(set_order_breadcrumbs(true, id));

    return () => {
      dispatch(set_order_breadcrumbs(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!orden_servicio || !muestras) {
    return <></>;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={7}>
        <CardMuestras
          orden_servicio={orden_servicio}
          muestras={muestras}
          style={{ height: '100%' }}
        />
      </Grid>
      <Grid item xs={12} md={5} container spacing={1} alignContent="flex-start">
        <Grid item xs={12}>
          <CardOrden orden_servicio={orden_servicio} />
        </Grid>
        <Grid item xs={12}>
          <CardCalc orden_servicio={orden_servicio} />
        </Grid>
      </Grid>
    </Grid>
  );
}
