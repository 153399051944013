import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as Api from 'api/api';
import { NormalizedModelObra } from 'api/types';

interface Props {
  disabled?: boolean;
  value: number | null;
  on_change: (event: any, value: number | null) => void;
  client_id?: number | null;
  required?: boolean;
}

export default function AsyncObraAutoComplete(props: Props) {
  const [loading, set_loading] = React.useState(false);
  const [options, set_options] = React.useState<NormalizedModelObra | null>(
    null,
  );

  React.useEffect(() => {
    let active = true;
    const clean = () => {
      active = false;
    };

    if (!props.client_id) return clean;

    const filters = { cliente_id: props.client_id };
    set_loading(true);
    set_options(null);
    Api.Obra.listar(filters)
      .then((response) => {
        if (active) set_options(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        set_loading(false);
      });

    return clean;
  }, [props.client_id]);

  return (
    <Autocomplete
      value={options ? props.value : null}
      onChange={props.on_change}
      disabled={props.disabled || !props.client_id}
      options={options ? options.ids : []}
      getOptionLabel={(id) => (options ? options.por_id[id].nombre : '')}
      loading={loading}
      loadingText="Cargando..."
      noOptionsText="Sin opciones"
      renderInput={(params) => (
        <TextField
          {...params}
          label="Obra"
          required={props.required}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
