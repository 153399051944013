import {
  ModelEnsayoPorMuestra,
  ModelMuestra,
  ModelOrdenServicioListar,
  NormalizedModelEnsayoPorMuestra,
  NormalizedModelMuestra,
  NormalizedModelOrdenServicio,
} from 'api/types';
import { Action } from 'redux';

export const action_types = {
  DEFINIR_FILTRO_ID_ORDEN: 'DEFINIR_FILTRO_ID_ORDEN',
  DEFINIR_FILTRO_CLIENTE: 'DEFINIR_FILTRO_CLIENTE',
  DEFINIR_FILTRO_ESTADO_LABORATORIO: 'DEFINIR_FILTRO_ESTADO_LABORATORIO',
  DEFINIR_FILTRO_ESTADO_TOTAL: 'DEFINIR_FILTRO_ESTADO_TOTAL',
  DEFINIR_FILTRO_FECHA_INICIAL: 'DEFINIR_FILTRO_FECHA_INICIAL',
  DEFINIR_FILTRO_FECHA_FINAL: 'DEFINIR_FILTRO_FECHA_FINAL',
  DEFINIR_FILTRO_TIPO_MUESTRA: 'DEFINIR_FILTRO_TIPO_MUESTRA',

  DEFINIR_ORDENES_SERVICIOS: 'DEFINIR_ORDENES_SERVICIOS',
  AGREGAR_ORDEN: 'AGREGAR_ORDEN',
  ACTUALIZAR_ORDEN: 'ACTUALIZAR_ORDEN',
  EDITAR_ORDEN: 'EDITAR_ORDEN',
  ELIMINAR_ORDEN: 'ELIMINAR_ORDEN',

  DEFINIR_ORDEN_SERVICIO_DETALLE: 'DEFINIR_ORDEN_SERVICIO_DETALLE',
  DEFINIR_MUESTRAS: 'DEFINIR_MUESTRAS',
  AGREGAR_MUESTRA: 'AGREGAR_MUESTRA',
  ACTUALIZAR_MUESTRA: 'ACTUALIZAR_MUESTRA',
  ELIMINAR_MUESTRA: 'ELIMINAR_MUESTRA',

  DEFINIR_MUESTRA_DETALLE: 'DEFINIR_MUESTRA_DETALLE',
  DEFINIR_ENSAYOS_POR_MUESTRA: 'DEFINIR_ENSAYOS_POR_MUESTRA',
  AGREGAR_ENSAYOS_POR_MUESTRA: 'AGREGAR_ENSAYOS_POR_MUESTRA',
  ACTUALIZAR_ENSAYO_POR_MUESTRA: 'ACTUALIZAR_ENSAYO_POR_MUESTRA',
  ELIMINAR_ENSAYO_POR_MUESTRA: 'ELIMINAR_ENSAYO_POR_MUESTRA',

  DEFINIR_PAGINACION: 'DEFINIR_PAGINACION',
} as const;

export interface PaginationParams {
  page: number;
  limit: number;
  count: number | null;
}

export interface OrdenServicioState {
  ui: {
    filtro_id_orden: number | null;
    filtro_cliente: number | null;
    filtro_estado_laboratorio: number | null;
    filtro_estado_total: number | null;
    filtro_fecha_inicial: Date | null;
    filtro_fecha_final: Date | null;
    filtro_tipo_muestra: number | null;

    paginacion: PaginationParams;
  };
  datos: {
    // Listado de ordenes de servicio
    ordenes_servicios: NormalizedModelOrdenServicio;

    // Detalles de una orden de servicio
    orden_servicio: ModelOrdenServicioListar | null;
    muestras: NormalizedModelMuestra;

    // Detalle de una muestra
    muestra: ModelMuestra | null;
    ensayos_por_muestra: NormalizedModelEnsayoPorMuestra;
  };
}

export interface ActionDefinirFiltroIdOrden extends Action {
  type: typeof action_types.DEFINIR_FILTRO_ID_ORDEN;
  value: number | null;
}

export interface ActionDefinirFiltroCliente extends Action {
  type: typeof action_types.DEFINIR_FILTRO_CLIENTE;
  value: number | null;
}

export interface ActionDefinirFiltroEstadoLaboratorio extends Action {
  type: typeof action_types.DEFINIR_FILTRO_ESTADO_LABORATORIO;
  value: number | null;
}

export interface ActionDefinirFiltroEstadoTotal extends Action {
  type: typeof action_types.DEFINIR_FILTRO_ESTADO_TOTAL;
  value: number | null;
}

export interface ActionDefinirFiltroFechaInicial extends Action {
  type: typeof action_types.DEFINIR_FILTRO_FECHA_INICIAL;
  value: Date | null;
}

export interface ActionDefinirFiltroFechaFinal extends Action {
  type: typeof action_types.DEFINIR_FILTRO_FECHA_FINAL;
  value: Date | null;
}

export interface ActionDefinirFiltroTipoMuestra extends Action {
  type: typeof action_types.DEFINIR_FILTRO_TIPO_MUESTRA;
  value: number | null;
}

export interface ActionDefinirPaginacion extends Action {
  type: typeof action_types.DEFINIR_PAGINACION;
  value: Partial<PaginationParams>;
}

export interface ActionDefinirOrdenesServicios extends Action {
  type: typeof action_types.DEFINIR_ORDENES_SERVICIOS;
  value: NormalizedModelOrdenServicio;
}

export interface ActionAgregarOrden extends Action {
  type: typeof action_types.AGREGAR_ORDEN;
  value: ModelOrdenServicioListar;
}

export interface ActionActualizarOrden extends Action {
  type: typeof action_types.ACTUALIZAR_ORDEN;
  value: ModelOrdenServicioListar;
}

export interface ActionEliminarOrden extends Action {
  type: typeof action_types.ELIMINAR_ORDEN;
  id: number;
}

export interface ActionDefinirOrdenServicioDetalle extends Action {
  type: typeof action_types.DEFINIR_ORDEN_SERVICIO_DETALLE;
  value: ModelOrdenServicioListar | null;
}

export interface ActionDefinirMuestras extends Action {
  type: typeof action_types.DEFINIR_MUESTRAS;
  value: NormalizedModelMuestra;
}

export interface ActionAgregarMuestra extends Action {
  type: typeof action_types.AGREGAR_MUESTRA;
  value: ModelMuestra;
}

export interface ActionActualizarMuestra extends Action {
  type: typeof action_types.ACTUALIZAR_MUESTRA;
  value: ModelMuestra;
}

export interface ActionEliminarMuestra extends Action {
  type: typeof action_types.ELIMINAR_MUESTRA;
  id: number;
}

export interface ActionDefinirMuestraDetalle extends Action {
  type: typeof action_types.DEFINIR_MUESTRA_DETALLE;
  value: ModelMuestra | null;
}

export interface ActionDefinirEnsayosPorMuestra extends Action {
  type: typeof action_types.DEFINIR_ENSAYOS_POR_MUESTRA;
  value: NormalizedModelEnsayoPorMuestra;
}

export interface ActionAgregarEnsayosPorMuestra extends Action {
  type: typeof action_types.AGREGAR_ENSAYOS_POR_MUESTRA;
  value: NormalizedModelEnsayoPorMuestra;
}

export interface ActionActualizarEnsayoPorMuestra extends Action {
  type: typeof action_types.ACTUALIZAR_ENSAYO_POR_MUESTRA;
  value: ModelEnsayoPorMuestra;
}

export interface ActionEliminarEnsayoPorMuestra extends Action {
  type: typeof action_types.ELIMINAR_ENSAYO_POR_MUESTRA;
  id: number;
}

export type OrdenServicioActions =
  | ActionDefinirFiltroIdOrden
  | ActionDefinirFiltroCliente
  | ActionDefinirFiltroEstadoLaboratorio
  | ActionDefinirFiltroEstadoTotal
  | ActionDefinirFiltroFechaInicial
  | ActionDefinirFiltroFechaFinal
  | ActionDefinirFiltroTipoMuestra
  | ActionDefinirPaginacion
  | ActionDefinirOrdenesServicios
  | ActionAgregarOrden
  | ActionActualizarOrden
  | ActionEliminarOrden
  | ActionDefinirOrdenServicioDetalle
  | ActionDefinirMuestras
  | ActionAgregarMuestra
  | ActionActualizarMuestra
  | ActionEliminarMuestra
  | ActionDefinirMuestraDetalle
  | ActionDefinirEnsayosPorMuestra
  | ActionAgregarEnsayosPorMuestra
  | ActionActualizarEnsayoPorMuestra
  | ActionEliminarEnsayoPorMuestra;
