import produce from 'immer';
import { RootState } from 'redux/reducers';
import { get_empty_normalized_object } from 'utilities/general';

import { GlobalState, GlobalActions, action_types } from './types';

const initial_state: GlobalState = {
  ui: {
    app_bar_title: '',
    loading: 0,
    edit_mode: false,
    breadcrumbs: [],
  },
  data: {
    // Initial data
    initial_data_loaded: false,
    tipo_ensayos: get_empty_normalized_object(),
    grupos_tipo_ensayos: get_empty_normalized_object(),
    tipo_muestras: get_empty_normalized_object(),
    muestras_colores: get_empty_normalized_object(),
    orden_tomada_en: get_empty_normalized_object(),
    muestra_cantidad_unidades: get_empty_normalized_object(),
    especificaciones: get_empty_normalized_object(),
    sedes: get_empty_normalized_object(),
    estados_tecnicos: get_empty_normalized_object(),
    estados_orden_laboratorio: get_empty_normalized_object(),
    estados_orden_total: get_empty_normalized_object(),
  },
};

const global = (state = initial_state, action: GlobalActions) =>
  produce(state, (draft) => {
    switch (action.type) {
      case action_types.SET_APP_BAR_TITLE:
        draft.ui.app_bar_title = action.value;
        break;
      case action_types.SET_LOADING:
        draft.ui.loading += action.value ? 1 : -1;
        break;
      case action_types.TOOGLE_EDIT_MODE:
        draft.ui.edit_mode = !draft.ui.edit_mode;
        break;
      case action_types.SET_BREADCRUMBS:
        draft.ui.breadcrumbs = action.value;
        break;

      case action_types.SET_INITIAL_DATA:
        draft.data = {
          ...draft.data,
          ...action.value,
          initial_data_loaded: true,
        };
        break;
      default:
        return draft;
    }
  });

export default global;

export const get_edit_mode = (state: RootState) => state.global.ui.edit_mode;

export const get_breadcrumbs = (state: RootState) =>
  state.global.ui.breadcrumbs;
