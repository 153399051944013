import {
  NormalizedModelEspecificacion,
  NormalizedModelEstadoTecnico,
  NormalizedModelGrupoTipoEnsayo,
  NormalizedModelMuestraCantidadUnidad,
  NormalizedModelOrdenTomadaEn,
  NormalizedModelTipoColor,
  NormalizedModelTipoEnsayo,
  NormalizedModelTipoMuestra,
  NormalizedModelEstadoOrdenLaboratorio,
  NormalizedModelEstadoOrdenTotal,
  NormalizedModelSede,
} from 'api/types';
import { Action } from 'redux';

export const action_types = {
  SET_APP_BAR_TITLE: 'SET_APP_BAR_TITLE',
  SET_LOADING: 'SET_LOADING',
  TOOGLE_EDIT_MODE: 'TOOGLE_EDIT_MODE',
  SET_BREADCRUMBS: 'SET_BREADCRUMBS',
  SET_INITIAL_DATA: 'SET_INITIAL_DATE',
} as const;

export interface BreadcrumbPath {
  name: string;
  path: string;
}

export interface InitialData {
  tipo_ensayos: NormalizedModelTipoEnsayo;
  grupos_tipo_ensayos: NormalizedModelGrupoTipoEnsayo;
  tipo_muestras: NormalizedModelTipoMuestra;
  muestras_colores: NormalizedModelTipoColor;
  orden_tomada_en: NormalizedModelOrdenTomadaEn;
  muestra_cantidad_unidades: NormalizedModelMuestraCantidadUnidad;
  especificaciones: NormalizedModelEspecificacion;
  sedes: NormalizedModelSede;
  estados_tecnicos: NormalizedModelEstadoTecnico;
  estados_orden_laboratorio: NormalizedModelEstadoOrdenLaboratorio;
  estados_orden_total: NormalizedModelEstadoOrdenTotal;
}

export interface GlobalState {
  ui: {
    app_bar_title: string;
    loading: number;
    edit_mode: boolean;
    breadcrumbs: BreadcrumbPath[];
  };
  data: InitialData & {
    initial_data_loaded: boolean;
  };
}

export interface ActionSetAppBarTitle extends Action {
  type: typeof action_types.SET_APP_BAR_TITLE;
  value: string;
}

export interface ActionSetLoading extends Action {
  type: typeof action_types.SET_LOADING;
  value: boolean;
}

export interface ActionToogleEditMode extends Action {
  type: typeof action_types.TOOGLE_EDIT_MODE;
}

export interface ActionSetBreadcrumbs extends Action {
  type: typeof action_types.SET_BREADCRUMBS;
  value: BreadcrumbPath[];
}

export interface ActionSetInitialData extends Action {
  type: typeof action_types.SET_INITIAL_DATA;
  value: InitialData;
}

export type GlobalActions =
  | ActionSetAppBarTitle
  | ActionSetLoading
  | ActionToogleEditMode
  | ActionSetBreadcrumbs
  | ActionSetInitialData;
