import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Fade } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    width: '100%',
    position: 'fixed',
    top: '0',
    zIndex: 50000,
  },
});

interface HeaderLoadingProps {
  loading: boolean;
}

export default function HeaderLoading(props: HeaderLoadingProps) {
  const classes = useStyles();
  const timeout = { enter: 50, exit: 1000 };

  return (
    <div className={classes.root}>
      <Fade in={props.loading} timeout={timeout}>
        <LinearProgress color="secondary" />
      </Fade>
    </div>
  );
}
