import { TableContainer, Typography, TablePagination } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { EstadoOrdenLaboratorio } from 'api/consts';
import {
  ModelOrdenServicioListar,
  NormalizedModelEstadoOrdenLaboratorio,
} from 'api/types';
import LinkRouter from 'components/material-ui/LinkRouter';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import { format_full_date, TimeDelta } from 'utilities/date';
import { get_order_id } from 'utilities/order_formaters';
import * as paths from 'config/paths';

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  rowSucces: {
    backgroundColor: theme.palette.success.light,
  },
  rowWarning: {
    backgroundColor: theme.palette.warning.light,
  },
  rowDanger: {
    backgroundColor: theme.palette.error.light,
  },
}));

interface OrderTableRowProps {
  order: ModelOrdenServicioListar;
  estados_orden_laboratorio: NormalizedModelEstadoOrdenLaboratorio;
  get_row_class_name: (value: any) => string;
}

function OrderTableRow(props: OrderTableRowProps) {
  const { order, estados_orden_laboratorio, get_row_class_name } = props;
  const { calc } = order;
  const timedelta = calc.desviacion_duracion_orden_laboratorio
    ? new TimeDelta(calc.desviacion_duracion_orden_laboratorio)
    : null;

  return (
    <TableRow className={get_row_class_name(order)}>
      <TableCell>
        <LinkRouter to={paths.orden_servicio_detalle(order.id)}>
          {get_order_id(order)}
        </LinkRouter>
      </TableCell>
      <TableCell>
        {calc.fecha_programada_salida_laboratorio
          ? format_full_date(calc.fecha_programada_salida_laboratorio)
          : ''}
      </TableCell>
      <TableCell>
        {calc.fecha_real_salida_laboratorio
          ? format_full_date(calc.fecha_real_salida_laboratorio)
          : ''}
      </TableCell>
      <TableCell>
        {calc.estado_orden_laboratorio
          ? estados_orden_laboratorio.por_id[calc.estado_orden_laboratorio]
              .nombre
          : ''}
      </TableCell>
      <TableCell align="right">
        {timedelta ? `${timedelta.days} días` : ''}
      </TableCell>
    </TableRow>
  );
}

export default function Orders() {
  const classes = useStyles();

  const rows_per_page_options = [15, 25, 50];
  const [page, set_page] = React.useState(0);
  const [rows_per_page, set_rows_per_page] = React.useState(
    rows_per_page_options[0],
  );

  const { orders, estados_orden_laboratorio } = useSelector(
    (state: RootState) => ({
      orders: state.statistics.data.orders,
      estados_orden_laboratorio: state.global.data.estados_orden_laboratorio,
    }),
  );

  const get_row_class_name = (order: ModelOrdenServicioListar) => {
    const estado = order.calc.estado_orden_laboratorio;

    if (
      estado === EstadoOrdenLaboratorio.TERMINADO_A_TIEMPO ||
      estado === EstadoOrdenLaboratorio.TERMINADO_ADELANTADO
    ) {
      return classes.rowSucces;
    }
    if (estado === EstadoOrdenLaboratorio.TERMINADO_ATRASADO) {
      return classes.rowDanger;
    }
    return classes.rowWarning;
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    set_page(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    set_rows_per_page(parseInt(event.target.value, 10));
    set_page(0);
  };

  return (
    <>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        Ordenes
      </Typography>

      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Id de orden</TableCell>
              <TableCell>Fecha programada</TableCell>
              <TableCell>Fecha real</TableCell>
              <TableCell>Estado</TableCell>
              <TableCell align="right">Desviación</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {orders &&
              orders.ids
                .slice(
                  page * rows_per_page,
                  page * rows_per_page + rows_per_page,
                )
                .map((id) => (
                  <OrderTableRow
                    key={id}
                    order={orders.por_id[id]}
                    estados_orden_laboratorio={estados_orden_laboratorio}
                    get_row_class_name={get_row_class_name}
                  />
                ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={rows_per_page_options}
        component="div"
        count={orders ? orders.ids.length : 0}
        rowsPerPage={rows_per_page}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
}
