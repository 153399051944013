import { EstadoOrdenLaboratorio } from 'api/consts';
import { NormalizedModelOrdenServicio } from 'api/types';
import ChartCompliancePercentage from 'components/charts/ChartCompliancePercentage';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import { Months } from 'utilities/date';
import { percent_round } from 'utilities/general';

function get_data(orders: NormalizedModelOrdenServicio) {
  const accu: Record<
    number,
    { name: string; on_time: number; late: number; on_progress: number }
  > = {};

  for (const id of orders.ids) {
    const estado = orders.por_id[id].calc.estado_orden_laboratorio;
    const { fecha_programada_salida_laboratorio } = orders.por_id[id].calc;

    if (!fecha_programada_salida_laboratorio) continue;

    const date = new Date(fecha_programada_salida_laboratorio);
    const month = date.getMonth();

    let on_time = 0;
    let late = 0;
    let in_progress = 0;
    if (
      estado === EstadoOrdenLaboratorio.TERMINADO_A_TIEMPO ||
      estado === EstadoOrdenLaboratorio.TERMINADO_ADELANTADO
    ) {
      on_time = 1;
    } else if (estado === EstadoOrdenLaboratorio.TERMINADO_ATRASADO) {
      late = 1;
    } else {
      in_progress = 1;
    }

    if (accu[month] === undefined) {
      accu[month] = {
        name: Months[month],
        on_time: 0,
        late: 0,
        on_progress: 0,
      };
    }

    accu[month].on_time += on_time;
    accu[month].late += late;
    accu[month].on_progress += in_progress;
  }

  const data = Object.values(accu).map((elem) => {
    const [on_time, late, on_progress] = percent_round(
      [elem.on_time, elem.late, elem.on_progress],
      1,
    );

    return {
      name: elem.name,
      on_time,
      late,
      on_progress,
    };
  });

  return data;
}

export default function LaboratoryCompliancePercentaje() {
  const orders = useSelector(
    (state: RootState) => state.statistics.data.orders,
  );

  if (!orders) return <></>;

  return <ChartCompliancePercentage data={get_data(orders)} />;
}
