import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
  createStyles({
    acceptButton: {
      color: theme.palette.error.main,
    },
    cancelButton: {
      color: theme.palette.info.main,
    },
  }),
);

interface AlertDialogProps {
  open: boolean;
  title: string;
  content: string;
  onCancel: (event: React.MouseEvent<HTMLElement>) => void;
  onAccept: (event: React.MouseEvent<HTMLElement>) => void;
}

export default function AlertDialog(props: AlertDialogProps) {
  const classes = useStyles();
  return (
    <Dialog open={props.open}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.onCancel}
          variant="outlined"
          className={classes.cancelButton}
          color="inherit"
          autoFocus>
          Cancelar
        </Button>
        <Button
          onClick={props.onAccept}
          variant="outlined"
          className={classes.acceptButton}
          color="inherit">
          Si
        </Button>
      </DialogActions>
    </Dialog>
  );
}
