import React from 'react';
import { useTypedSelector } from 'redux/reducers';
import { verificar_token } from 'utilities/auth';

interface Props {
  roles: readonly string[];
  children: React.ReactElement;
}

export default function ProtectedComponent(props: Props) {
  const token = useTypedSelector((state) => state.auth.token);

  if (verificar_token(token, props.roles)) {
    return props.children;
  }

  return <></>;
}
