import {
  Button,
  createStyles,
  DialogActions,
  DialogContent,
  makeStyles,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import MDEditor from '@uiw/react-md-editor';
import React from 'react';
import useWindowSize from 'utilities/hooks/useWindowSize';

const useStyles = makeStyles((theme) =>
  createStyles({
    saveButton: {
      color: theme.palette.info.main,
    },
    cancelButton: {
      color: theme.palette.error.main,
    },
  }),
);

interface Props {
  value: string;
  on_cancel: () => void;
  on_save: (value: string) => void;
}

export default function DialogMDEditor(props: Props) {
  const classes = useStyles();
  const { height } = useWindowSize();

  const [edited_value, set_edited_value] = React.useState('');

  React.useEffect(() => {
    set_edited_value(props.value);
  }, [props.value]);

  const handle_save = (event: any) => {
    props.on_save(edited_value);
  };

  return (
    <Dialog
      fullScreen={true}
      open={true}
      onClose={props.on_cancel}
      maxWidth={false}
      aria-labelledby="dialog-md-editor-title">
      <DialogContent>
        <MDEditor
          value={edited_value}
          onChange={(value) => {
            value ? set_edited_value(value) : set_edited_value('');
          }}
          visiableDragbar={false}
          height={height - 110}
        />
      </DialogContent>

      <DialogActions>
        <Button
          onClick={props.on_cancel}
          variant="outlined"
          className={classes.cancelButton}
          color="inherit"
          autoFocus>
          Cancelar
        </Button>
        <Button
          onClick={handle_save}
          variant="outlined"
          className={classes.saveButton}
          color="inherit">
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
