import * as Api from 'api/api';
import {
  ModelEnsayoPorMuestra,
  ModelEnsayoPorMuestraCrear,
  ModelMuestra,
  ModelOrdenServicio,
  ModelOrdenServicioListar,
  NormalizedModelEnsayoPorMuestra,
  NormalizedModelMuestra,
  NormalizedModelOrdenServicio,
} from 'api/types';
import { AppThunk } from 'redux/store';
import {
  date_final_hour_day,
  date_initial_hour_day,
  get_empty_normalized_object,
} from 'utilities/general';
import { set_loading, handle_http_error } from '../global/actions';
import { query_filters } from './reducers';
import {
  ActionActualizarEnsayoPorMuestra,
  ActionAgregarEnsayosPorMuestra,
  ActionDefinirEnsayosPorMuestra,
  ActionDefinirFiltroCliente,
  ActionDefinirFiltroEstadoLaboratorio,
  ActionDefinirFiltroEstadoTotal,
  ActionDefinirFiltroFechaFinal,
  ActionDefinirFiltroFechaInicial,
  ActionDefinirFiltroIdOrden,
  ActionDefinirFiltroTipoMuestra,
  ActionDefinirMuestraDetalle,
  ActionDefinirMuestras,
  ActionDefinirOrdenesServicios,
  ActionDefinirOrdenServicioDetalle,
  ActionDefinirPaginacion,
  action_types,
  PaginationParams,
} from './types';

/**********************************************************************************************************************
 ************************************************ Actions creators ****************************************************
 **********************************************************************************************************************/

/**
 * Definir el fitro de id de orden
 */
export const definir_filtro_id_orden = (
  value: number | null,
): ActionDefinirFiltroIdOrden => ({
  type: action_types.DEFINIR_FILTRO_ID_ORDEN,
  value,
});

/**
 * Definir el fitro de cliente
 */
export const definir_filtro_cliente = (
  value: number | null,
): ActionDefinirFiltroCliente => ({
  type: action_types.DEFINIR_FILTRO_CLIENTE,
  value,
});

/**
 * Definir el filtro de estado en el laboratorio
 */
export const definir_filtro_estado_laboratorio = (
  value: number | null,
): ActionDefinirFiltroEstadoLaboratorio => ({
  type: action_types.DEFINIR_FILTRO_ESTADO_LABORATORIO,
  value,
});

/**
 * Definir el filtro de estado total
 */
export const definir_filtro_estado_total = (
  value: number | null,
): ActionDefinirFiltroEstadoTotal => ({
  type: action_types.DEFINIR_FILTRO_ESTADO_TOTAL,
  value,
});

/**
 * Definir filtro de fecha inicial
 */
export const definir_filtro_fecha_inicial = (
  value: Date | null,
): ActionDefinirFiltroFechaInicial => ({
  type: action_types.DEFINIR_FILTRO_FECHA_INICIAL,
  value,
});

/**
 * Definir filtro de fecha final
 */
export const definir_filtro_fecha_final = (
  value: Date | null,
): ActionDefinirFiltroFechaFinal => ({
  type: action_types.DEFINIR_FILTRO_FECHA_FINAL,
  value,
});

/**
 * Definir filtro de tipo de muestra
 */
export const definir_filtro_tipo_muestra = (
  value: number | null,
): ActionDefinirFiltroTipoMuestra => ({
  type: action_types.DEFINIR_FILTRO_TIPO_MUESTRA,
  value,
});

/**
 * Definir los valores de la paginación
 */
export const definir_paginacion = (
  value: Partial<PaginationParams>,
): ActionDefinirPaginacion => {
  return { type: action_types.DEFINIR_PAGINACION, value };
};

export const definir_ordenes_servicios = (
  value: NormalizedModelOrdenServicio,
): ActionDefinirOrdenesServicios => ({
  type: action_types.DEFINIR_ORDENES_SERVICIOS,
  value,
});

export const definir_orden_servicio_detalle = (
  value: ModelOrdenServicioListar | null,
): ActionDefinirOrdenServicioDetalle => ({
  type: action_types.DEFINIR_ORDEN_SERVICIO_DETALLE,
  value,
});

export const definir_muestras = (
  value: NormalizedModelMuestra,
): ActionDefinirMuestras => ({
  type: action_types.DEFINIR_MUESTRAS,
  value,
});

export const definir_muestra_detalle = (
  value: ModelMuestra | null,
): ActionDefinirMuestraDetalle => ({
  type: action_types.DEFINIR_MUESTRA_DETALLE,
  value,
});

export const definir_ensayos_por_muestra = (
  value: NormalizedModelEnsayoPorMuestra,
): ActionDefinirEnsayosPorMuestra => ({
  type: action_types.DEFINIR_ENSAYOS_POR_MUESTRA,
  value,
});

export const agregar_ensayos_por_muestra = (
  value: NormalizedModelEnsayoPorMuestra,
): ActionAgregarEnsayosPorMuestra => ({
  type: action_types.AGREGAR_ENSAYOS_POR_MUESTRA,
  value,
});

export const actualizar_ensayo_por_muestra = (
  value: ModelEnsayoPorMuestra,
): ActionActualizarEnsayoPorMuestra => ({
  type: action_types.ACTUALIZAR_ENSAYO_POR_MUESTRA,
  value,
});

/**********************************************************************************************************************
 ******************************************************* Thunk ********************************************************
 **********************************************************************************************************************/

/**
 * Obtener las ordenes de servicio, filtrandolas por cliente y estado
 * siempre y cuando los filtros esten disponibles
 */
export const obtener_ordenes_servicios = (reset_page = true): AppThunk => (
  dispatch,
  getState,
) => {
  const _filtros = query_filters(getState());
  const { paginacion } = getState().orden_servicio.ui;

  const page = reset_page ? 1 : paginacion.page;

  const filtros = {
    limit: paginacion.limit,
    offset: (page - 1) * paginacion.limit,
    id: _filtros.filtro_id_orden,
    cliente_id: _filtros.filtro_cliente,
    estado_orden_laboratorio: _filtros.filtro_estado_laboratorio,
    estado_orden_total: _filtros.filtro_estado_total,
    fecha_inicial: _filtros.filtro_fecha_inicial
      ? date_initial_hour_day(_filtros.filtro_fecha_inicial)
      : null,
    fecha_final: _filtros.filtro_fecha_final
      ? date_final_hour_day(_filtros.filtro_fecha_final)
      : null,
    tipo_muestra: _filtros.filtro_tipo_muestra,
  };

  dispatch(set_loading(true));
  // dispatch(definir_ordenes_servicios([]));

  return Api.OrdenServicio.listar(filtros)
    .then((response) =>
      Promise.all([
        dispatch(definir_ordenes_servicios(response.data.results)),
        dispatch(definir_paginacion({ page, count: response.data.count })),
      ]),
    )
    .catch((error) => dispatch(handle_http_error(error)))
    .finally(() => dispatch(set_loading(false)));
};

/**
 *
 */
export const actualizar_orden = (
  orden: ModelOrdenServicio,
): AppThunk<Promise<any>> => (dispatch) => {
  dispatch(set_loading(true));

  return Api.OrdenServicio.actualizar(orden.id, orden)
    .then((response) => Api.OrdenServicio.detalle(response.data.id))
    .then((response) =>
      dispatch({ type: action_types.ACTUALIZAR_ORDEN, value: response.data }),
    )
    .catch((error) => dispatch(handle_http_error(error)))
    .finally(() => dispatch(set_loading(false)));
};

/**
 *
 */
export const eliminar_orden = (id: number): AppThunk<Promise<any>> => (
  dispatch,
) => {
  dispatch(set_loading(true));

  return Api.OrdenServicio.eliminar(id)
    .then(() => dispatch({ type: action_types.ELIMINAR_ORDEN, id }))
    .catch((error) => dispatch(handle_http_error(error)))
    .finally(() => dispatch(set_loading(false)));
};

/***********************************************************************************************************************
 *                                      Orden servicio detalle
 **********************************************************************************************************************/

/**
 *
 */
export const obtener_orden_servicio_detalle = (id: number): AppThunk => (
  dispatch,
) => {
  dispatch(set_loading(true));
  dispatch(definir_orden_servicio_detalle(null));

  return Api.OrdenServicio.detalle(id)
    .then((response) => dispatch(definir_orden_servicio_detalle(response.data)))
    .catch((error) => dispatch(handle_http_error(error)))
    .finally(() => dispatch(set_loading(false)));
};

/**
 *
 */
export const obtener_muestras = (orden_servicio_id: number): AppThunk => (
  dispatch,
) => {
  const filtros = { orden_servicio_id };

  dispatch(set_loading(true));
  dispatch(definir_muestras(get_empty_normalized_object<ModelMuestra>()));

  return Api.Muestra.listar(filtros)
    .then((response) => dispatch(definir_muestras(response.data)))
    .catch((error) => dispatch(handle_http_error(error)))
    .finally(() => dispatch(set_loading(false)));
};

/**
 *
 */
export const actualizar_muestra = (
  muestra: ModelMuestra,
): AppThunk<Promise<any>> => (dispatch) => {
  dispatch(set_loading(true));

  return Api.Muestra.actualizar(muestra.id, muestra)
    .then((response) =>
      dispatch({ type: action_types.ACTUALIZAR_MUESTRA, value: response.data }),
    )
    .catch((error) => dispatch(handle_http_error(error)))
    .finally(() => dispatch(set_loading(false)));
};

/**
 *
 */
export const eliminar_muestra = (id: number): AppThunk<Promise<any>> => (
  dispatch,
) => {
  dispatch(set_loading(true));

  return Api.Muestra.eliminar(id)
    .then(() => dispatch({ type: action_types.ELIMINAR_MUESTRA, id }))
    .catch((error) => dispatch(handle_http_error(error)))
    .finally(() => dispatch(set_loading(false)));
};

/***********************************************************************************************************************
 *                                      Muestra detalle
 **********************************************************************************************************************/

/**
 *
 */
export const obtener_muestra_detalle = (id: number): AppThunk => (dispatch) => {
  dispatch(set_loading(true));
  dispatch(definir_muestra_detalle(null));

  return Api.Muestra.detalle(id)
    .then((response) => dispatch(definir_muestra_detalle(response.data)))
    .catch((error) => dispatch(handle_http_error(error)))
    .finally(() => dispatch(set_loading(false)));
};

/**
 *
 */
export const obtener_ensayos_por_muestra = (muestra_id: number): AppThunk => (
  dispatch,
) => {
  const filtros = { muestra_id };

  dispatch(set_loading(true));
  dispatch(
    definir_ensayos_por_muestra(
      get_empty_normalized_object<ModelEnsayoPorMuestra>(),
    ),
  );

  return Api.EnsayoPorMuestra.listar(filtros)
    .then((response) => dispatch(definir_ensayos_por_muestra(response.data)))
    .catch((error) => dispatch(handle_http_error(error)))
    .finally(() => dispatch(set_loading(false)));
};

/**
 *
 */
export const crear_ensayos_por_muestra = (
  ensayos: ModelEnsayoPorMuestraCrear[],
): AppThunk<Promise<any>> => async (dispatch, getState) => {
  const { muestra } = getState().orden_servicio.datos;

  if (!muestra) return;

  dispatch(set_loading(true));
  return (
    Api.EnsayoPorMuestra.crear(ensayos)
      // .then((response) => dispatch(agregar_ensayos_por_muestra(response.data)))
      .then((response) => dispatch(obtener_ensayos_por_muestra(muestra.id)))
      .catch((error) => handle_http_error(error))
      .finally(() => dispatch(set_loading(false)))
  );
};

/**
 *
 */
export const editar_ensayo_por_muestra = (
  ensayo: ModelEnsayoPorMuestra,
): AppThunk<Promise<any>> => async (dispatch, getState) => {
  const { muestra } = getState().orden_servicio.datos;

  if (!muestra) return;

  dispatch(set_loading(true));
  return (
    Api.EnsayoPorMuestra.actualizar(ensayo.id, ensayo)
      // .then((response) => dispatch(actualizar_ensayo_por_muestra(response.data)))
      .then((response) => dispatch(obtener_ensayos_por_muestra(muestra.id)))
      .catch((error) => dispatch(handle_http_error(error)))
      .finally(() => dispatch(set_loading(false)))
  );
};

/**
 *
 */
export const eliminar_ensayo_por_muestra = (
  id: number,
): AppThunk<Promise<any>> => async (dispatch, getState) => {
  const { muestra } = getState().orden_servicio.datos;

  if (!muestra) return;

  dispatch(set_loading(true));
  return (
    Api.EnsayoPorMuestra.eliminar(id)
      // .then(() => dispatch({ type: acciones.ELIMINAR_ENSAYO_POR_MUESTRA, id }))
      .then((response) => dispatch(obtener_ensayos_por_muestra(muestra.id)))
      .catch((error) => dispatch(handle_http_error(error)))
      .finally(() => dispatch(set_loading(false)))
  );
};
