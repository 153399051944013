import {
  Button,
  Checkbox,
  DialogActions,
  Grid,
  makeStyles,
  TextField,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Autocomplete } from '@material-ui/lab';
import { ModelEnsayoPorMuestraCrear } from 'api/types';
import React from 'react';
import { useSelector } from 'react-redux';
import { crear_ensayos_por_muestra } from 'redux/orden_servicio/actions';
import { RootState } from 'redux/reducers';
import { useTypedDispatch } from 'redux/store';
import { array_difference } from 'utilities/general';
import styles from './DialogCrearEnsayos.styles';

const useStyles = makeStyles(styles);

const query_selector = (state: RootState) => ({
  tipo_ensayos: state.global.data.tipo_ensayos,
  grupos_tipo_ensayos: state.global.data.grupos_tipo_ensayos,
});

interface Props {
  open: boolean;
  on_cancel: (event?: FixLater) => void;
  muestra: number;
  exclude: number[];
}

export default function DialogCreateTests(props: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const full_screen = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useTypedDispatch();
  const { tipo_ensayos, grupos_tipo_ensayos } = useSelector(query_selector);

  const initial_data: { muestra: number; ensayos: number[] } = {
    muestra: props.muestra,
    ensayos: [],
  };

  const [data, set_data] = React.useState(initial_data);

  const [group_test_id, set_group_test_id] = React.useState<number | null>(
    null,
  );

  const handle_change_group = (
    event: any,
    new_group_test_id: number | null,
  ) => {
    const old_group_test_id = group_test_id;

    let ensayos = [...data.ensayos];

    // Remove old values
    if (old_group_test_id !== null) {
      const group_tests = grupos_tipo_ensayos.por_id[old_group_test_id].ensayos;
      ensayos = array_difference(ensayos, group_tests);
    }

    // Add new values
    if (new_group_test_id !== null) {
      const group_tests = grupos_tipo_ensayos.por_id[new_group_test_id].ensayos;

      const new_tests = array_difference(group_tests, [
        ...ensayos,
        ...props.exclude,
      ]);
      ensayos = ensayos.concat(new_tests);
    }
    emulate_event('ensayos', ensayos);

    set_group_test_id(new_group_test_id);
  };

  const handle_change = (event: FixLater) => {
    const { name, value } = event.target;
    set_data({
      ...data,
      [name]: value,
    });
  };

  const emulate_event = (name: string, value: FixLater) => {
    const event = {
      target: {
        name,
        value,
      },
    };
    handle_change(event);
  };

  function filter_tests(ids: number[], _value: string) {
    const value = _value.toLowerCase();
    return ids.filter(
      (id) =>
        tipo_ensayos.por_id[id].codigo.toLowerCase().includes(value) ||
        tipo_ensayos.por_id[id].nombre.toLowerCase().includes(value),
    );
  }

  const handle_submit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data_to_submit: ModelEnsayoPorMuestraCrear[] = data.ensayos.map(
      (tipo_ensayo) => ({
        muestra: data.muestra,
        tipo_ensayo: tipo_ensayo,
      }),
    );

    dispatch(crear_ensayos_por_muestra(data_to_submit)).then((v) => {
      props.on_cancel();
    });
  };

  const form_id = 'form-create-tests';

  return (
    <Dialog
      fullScreen={full_screen}
      open={props.open}
      onClose={props.on_cancel}
      maxWidth={false}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">Agregar ensayos</DialogTitle>

      <DialogContent className={classes.root}>
        <form className={classes.form} id={form_id} onSubmit={handle_submit}>
          <div className={classes.note}>
            Nota: Los ensayos que ya se agregaron a la muestra, no se pueden
            agregar de nuevo.
          </div>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                value={data.ensayos}
                onChange={(event, value) => {
                  emulate_event('ensayos', value);
                }}
                multiple
                options={tipo_ensayos.ids}
                getOptionLabel={(id) => tipo_ensayos.por_id[id].codigo}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      required: data.ensayos.length === 0,
                    }}
                    variant="outlined"
                    label="Ensayos"
                    required
                  />
                )}
                renderOption={(id, { selected }) => (
                  <>
                    <Checkbox style={{ marginRight: 8 }} checked={selected} />
                    {tipo_ensayos.por_id[id].codigo}:{' '}
                    {tipo_ensayos.por_id[id].nombre}
                  </>
                )}
                filterOptions={(ids, state) =>
                  filter_tests(ids, state.inputValue)
                }
                getOptionDisabled={(id) => props.exclude.includes(id)}
                disableCloseOnSelect
              />
            </Grid>

            <Grid item xs={12}>
              <Autocomplete
                value={group_test_id}
                onChange={handle_change_group}
                options={grupos_tipo_ensayos.ids}
                getOptionLabel={(id) => grupos_tipo_ensayos.por_id[id].nombre}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Grupo ensayos"
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={props.on_cancel}>
          Cancelar
        </Button>

        <Button
          variant="contained"
          color="primary"
          type="submit"
          form={form_id}>
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
