import {
  createStyles,
  IconButton,
  makeStyles,
  Theme,
  Tooltip,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import { LOGIN_PATH } from 'config/paths';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { refrescar_token } from 'redux/auth/actions';
import { useTypedSelector } from 'redux/reducers';
import { verificar_roles } from 'utilities/auth';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
);

interface Props extends RouteProps {
  roles?: string[];
}

enum Access {
  CON_PERMISO,
  SIN_PERMISO,
  SIN_TOKEN,
  VERIFICANDO,
  ERROR_DE_RED,
}

export default function ProtectedRoute(props: Props) {
  const classes = useStyles();
  const { roles, ...route_props } = props;
  const dispatch = useDispatch();

  const [access, set_access] = useState<Access>(Access.VERIFICANDO);

  const { token, refrescar_token_error } = useTypedSelector((state) => ({
    token: state.auth.token,
    refrescar_token_error: state.auth.refrescar_token_error,
  }));

  useEffect(() => {
    if (token) {
      if (props.roles) {
        const permis = verificar_roles(token, props.roles);
        const _access = permis ? Access.CON_PERMISO : Access.SIN_PERMISO;
        set_access(_access);
      } else {
        set_access(Access.CON_PERMISO);
      }
    } else if (refrescar_token_error) {
      if (refrescar_token_error.response) {
        set_access(Access.SIN_TOKEN);
      } else if (refrescar_token_error.request) {
        set_access(Access.ERROR_DE_RED);
      } else {
        set_access(Access.SIN_TOKEN);
      }
    } else {
      // Try refresh token
      dispatch(refrescar_token());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, refrescar_token_error, props.roles]);

  const reconnect = () => {
    dispatch(refrescar_token());
    set_access(Access.VERIFICANDO);
  };

  if (access === Access.CON_PERMISO) {
    return <Route {...route_props} />;
  }

  if (access === Access.SIN_PERMISO) {
    return <>Sin permiso</>;
  }

  if (access === Access.ERROR_DE_RED) {
    return (
      <div className={classes.root}>
        <Tooltip title="Reintentar" placement="top">
          <IconButton onClick={reconnect}>
            <RotateRightIcon color="primary" fontSize="large" />
          </IconButton>
        </Tooltip>
        <p>Error de red. No se ha podido conectar con el servidor</p>
      </div>
    );
  }

  if (access === Access.SIN_TOKEN) {
    return (
      <Redirect
        to={{
          pathname: LOGIN_PATH,
          state: { redirect: window.location.pathname },
        }}
      />
    );
  }

  return (
    <div className={classes.root}>
      <CircularProgress color="primary" size={60} />
      <p>Verificando sesión</p>
    </div>
  );
}
