import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Copyright from 'components/Copyright';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { login } from 'redux/auth/actions';
import { verificar_token } from 'utilities/auth';
import * as paths from 'config/paths';
import { useTypedSelector } from 'redux/reducers';
import CircularProgress from '@material-ui/core/CircularProgress';
import { IconButton, InputAdornment, Tooltip } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
// import Checkbox from '@material-ui/core/Checkbox';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Grid from '@material-ui/core/Grid';
// import Link from '@material-ui/core/Link';

function Error(props: any) {
  const { error, classes } = props;
  let msg = '';
  if (error) {
    // Se puede especificar aun mas, para dar mensajes personalizados de acuerdo al tipo de error
    if (error.response) {
      msg = 'Usuario y/o contraseña incorrecta';
    } else if (error.request) {
      msg = 'Error de red';
    } else {
      msg = 'Error inesperado';
    }
  }
  return <Typography className={classes.error}>{msg}</Typography>;
}

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    loading: {
      margin: theme.spacing(1),
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    error: {
      color: theme.palette.error.dark,
    },
  });

const useStyles = makeStyles(styles);

type RouterProps = { location: { state: any } };

export default function Login(props: RouterProps) {
  const classes = useStyles();
  const loading = useTypedSelector((state) => state.global.ui.loading);
  const dispatch = useDispatch();
  const [username, set_username] = React.useState('');
  const [password, set_password] = React.useState('');
  const [show_password, set_show_password] = React.useState(false);

  const toogle_show_password = () => {
    set_show_password(!show_password);
  };

  const { token, login_error } = useTypedSelector((state) => ({
    token: state.auth.token,
    login_error: state.auth.login_error,
  }));

  const handle_login = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    dispatch(login(username, password));
  };

  // Si tiene un token válido, quiere decir que ya inició sesión
  if (verificar_token(token)) {
    const redirect =
      props.location.state && props.location.state.redirect
        ? props.location.state.redirect
        : paths.MAIN_PATH;
    return <Redirect to={redirect} />;
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        {Boolean(loading) ? (
          <CircularProgress color="secondary" className={classes.loading} />
        ) : (
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
        )}

        <Typography component="h1" variant="h5">
          Suelos y pavimentos
        </Typography>

        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Usuario"
            name="username"
            autoComplete="email"
            autoFocus
            value={username}
            onChange={(event) => {
              set_username(event.target.value);
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Contraseña"
            type={show_password ? 'text' : 'password'}
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(event) => {
              set_password(event.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip
                    placement="top"
                    title={
                      show_password
                        ? 'Ocultar contraseña'
                        : 'Mostrar contraseña'
                    }>
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={toogle_show_password}>
                      {show_password ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" checked />}
            label="Seguir conectado"
          /> */}
          <Error error={login_error} classes={classes} />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handle_login}
            disabled={Boolean(loading)}>
            Iniciar sesión
          </Button>
          {/* <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                ¿Olvidaste tu contraseña?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2">
                Crear cuenta
              </Link>
            </Grid>
          </Grid> */}
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
