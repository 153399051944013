import { ADMIN } from 'config/user_groups';
import { verificar_token } from 'utilities/auth';
import { to_array } from 'utilities/general';
import { AuthState, action_types, AuthActions } from './types';
import { RootState } from 'redux/reducers';

export const initial_state: AuthState = {
  token: null,
  login_error: null,
  refrescar_token_error: null,
} as const;

const auth = (state = initial_state, action: AuthActions) => {
  switch (action.type) {
    case action_types.SET_LOGIN_ERROR:
      return {
        ...state,
        token: null,
        login_error: action.error,
        refrescar_token_error: false,
      };

    case action_types.SET_LOGIN:
      return {
        ...state,
        token: action.token,
        login_error: null,
      };

    case action_types.SET_REFRESH_TOKEN_ERROR:
      return {
        ...state,
        refrescar_token_error: action.error,
      };

    default:
      return state;
  }
};

export default auth;

/**
 * Verify that state token has at least one role. (Admin can do everything)
 * @param  state
 */
export const token_verify_roles = (state: RootState) => (
  roles: string | readonly string[] = [],
) => {
  roles = to_array(roles);
  roles = roles.concat(ADMIN);
  return verificar_token(state.auth.token, roles);
};
