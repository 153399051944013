import { Button, DialogActions } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ModelOrdenServicio } from 'api/types';
import React from 'react';
import { actualizar_orden } from 'redux/orden_servicio/actions';
import { useTypedDispatch } from 'redux/store';
import { get_order_id } from 'utilities/order_formaters';
import FormOrden from './FormOrden/FormOrden';

interface Props {
  open: boolean;
  orden_servicio: ModelOrdenServicio;
  on_cancel: (event?: FixLater) => void;
}

export default function DialogEditOrder(props: Props) {
  const theme = useTheme();
  const full_screen = useMediaQuery(theme.breakpoints.down('sm'));
  const { open, on_cancel } = props;
  const dispatch = useTypedDispatch();

  const [datos, definir_datos] = React.useState(props.orden_servicio);

  const handle_change = (name: keyof typeof datos, value: any) => {
    definir_datos({
      ...datos,
      [name]: value,
    });
  };

  const handle_submit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(actualizar_orden(datos as ModelOrdenServicio)).then(() => {
      on_cancel();
    });
  };

  const form_id = 'form-edit-orden-servicio';

  return (
    <Dialog
      fullScreen={full_screen}
      open={open}
      onClose={on_cancel}
      maxWidth={false}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">
        {`Editar orden de servicio: ${get_order_id(props.orden_servicio)}`}
      </DialogTitle>

      <DialogContent>
        <form onSubmit={handle_submit} id={form_id}>
          <FormOrden datos={datos} handle_change={handle_change} />
        </form>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={on_cancel}>
          Cancelar
        </Button>

        <Button
          variant="contained"
          color="primary"
          type="submit"
          form={form_id}>
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
