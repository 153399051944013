import BarChartIcon from '@material-ui/icons/BarChart';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ListAltIcon from '@material-ui/icons/ListAlt';
import * as paths from 'config/paths';
import Logout from 'views/Auth/Logout';
import OrdenServicioListar from 'views/OrdenServicio/OrdenServicioListar/OrdenServicioListar';
import Statistics from 'views/Statistics/Statistics2';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import Administracion from 'views/Administracion/Administracion';

export const grupos = [
  { nombre: 'principal', titulo: '' },
  { nombre: 'usuario', titulo: '' },
] as const;

const routes = [
  {
    path: paths.ORDEN_SERVICIO_PATH,
    optionalParams: '',
    name: 'Ordenes de servicio',
    Icon: ListAltIcon,
    Component: OrdenServicioListar,
    grupo: 'principal',
  },
  {
    path: paths.ESTADISTICAS_PATH,
    optionalParams: '',
    name: 'Estadísticas',
    Icon: BarChartIcon,
    Component: Statistics,
    grupo: 'principal',
  },

  {
    path: paths.ADMINISTRACION_PATH,
    optionalParams: '',
    name: 'Administración',
    Icon: SupervisorAccountIcon,
    Component: Administracion,
    grupo: 'principal',
  },

  {
    path: paths.CERRAR_SESION_PATH,
    optionalParams: '',
    name: 'Cerrar sesión',
    Icon: ExitToAppIcon,
    Component: Logout,
    grupo: 'usuario',
  },
] as const;

export default routes;
