import { Action, combineReducers } from 'redux';
import auth from './auth/reducers';
import orden_servicio from './orden_servicio/reducers';
import global from './global/reducers';
import statistics from './statistics/reducers';
import { useSelector, TypedUseSelectorHook } from 'react-redux';

const action_types = {
  RESET_APP: 'RESET_APP',
} as const;

interface ActionResetApp extends Action {
  type: typeof action_types.RESET_APP;
}

function reset_app(): ActionResetApp {
  return { type: action_types.RESET_APP };
}

const reducers = combineReducers({
  auth,
  global,
  orden_servicio,
  statistics,
});

type ReducersParameters = Parameters<typeof reducers>;
type RootState = ReturnType<typeof reducers>;
type AppActions = ReducersParameters[1] | ActionResetApp;

const root_reducer = (state: ReducersParameters[0], action: AppActions) => {
  if (action.type === action_types.RESET_APP) {
    state = undefined;
  }
  return reducers(state, action as ReducersParameters[1]);
};

const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export type { RootState, AppActions };
export { useTypedSelector, reset_app };
export default root_reducer;
