import { useTheme } from '@material-ui/core';
import React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

interface ChartAverageLateDaysProps {
  data: Array<{ name: string; days: number }>;
  days_label?: string;
}

interface FormatedData {
  name: string;
  late: number | null;
  on_time: number | null;
}

export default function ChartAverageLateDays(props: ChartAverageLateDaysProps) {
  const theme = useTheme();
  const { data, days_label = 'Días' } = props;

  const [formated_data, set_formated_data] = React.useState<FormatedData[]>([]);

  React.useEffect(() => {
    const _formated_data = data.map((obj) => ({
      name: obj.name,
      late: obj.days >= 0 ? obj.days : null,
      on_time: obj.days < 0 ? obj.days : null,
    }));

    set_formated_data(_formated_data);

    return () => {};
  }, [data]);

  return (
    <ResponsiveContainer>
      <BarChart
        data={formated_data}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis
          unit="días"
          domain={[
            (data_min: number) => Math.floor(data_min) - 1,
            (data_max: number) => Math.ceil(data_max) + 1,
          ]}
        />
        <Tooltip />
        <ReferenceLine y={0} stroke="#000" />
        <Bar
          dataKey="late"
          unit="días"
          name={days_label}
          stackId="1"
          fill={theme.palette.error.light}
        />
        <Bar
          dataKey="on_time"
          unit="días"
          name={days_label}
          stackId="1"
          fill={theme.palette.success.light}
        />
      </BarChart>
    </ResponsiveContainer>
  );
}
