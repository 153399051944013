import * as Api from 'api/api';
import * as paths from 'config/paths';
import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import FormOrden from './FormOrden/FormOrden';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ModelOrdenServicio, ModelOrdenServicioEdit } from 'api/types';
import { Button, DialogActions } from '@material-ui/core';
import { useTypedSelector } from 'redux/reducers';

interface Props {
  open: boolean;
  on_close: (event: FixLater) => void;
}

function DialogCreateOrder(props: Props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const sedes = useTypedSelector((state) => state.global.data.sedes);
  const { open, on_close } = props;

  const default_sede = sedes.ids.length ? sedes.ids[0] : null;

  const datos_iniciales: ModelOrdenServicioEdit = {
    fecha_recibido: new Date().toISOString(),
    sede: default_sede,
    ubicacion_muestras: default_sede,
    numero_orden: '',
    cliente: null,
    obra: null,
    responsable: '',
    contacto: '',
    especificacion: null,
    tomada_en: null,
    observaciones: '',

    estado_tecnico: null,
    genera_informe: true,
    fecha_real_fin_calculo_digitacion: null,
    fecha_real_salida_informe: null,
    numero_informe: '',
    informe: null,
  } as const;

  const [datos, definir_datos] = React.useState(datos_iniciales);

  const handle_change = (name: keyof typeof datos, value: any) => {
    definir_datos({
      ...datos,
      [name]: value,
    });
  };

  // Hooks
  const [
    orden_guardada,
    definir_orden_guardada,
  ] = useState<ModelOrdenServicio | null>(null);

  const handle_submit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    Api.OrdenServicio.crear(datos)
      .then((response) => {
        definir_orden_guardada(response.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  if (orden_guardada)
    return <Redirect to={paths.orden_servicio_detalle(orden_guardada.id)} />;

  const form_id = 'form-create-orden-servicio';

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={on_close}
      maxWidth={false}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">
        {'Crear orden de servicio'}
      </DialogTitle>

      <DialogContent>
        <form onSubmit={handle_submit} id={form_id}>
          <FormOrden datos={datos} handle_change={handle_change} />
        </form>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={on_close}>
          Cancelar
        </Button>

        <Button
          variant="contained"
          color="primary"
          type="submit"
          form={form_id}>
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DialogCreateOrder;
