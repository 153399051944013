import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ModelMuestra, ModelMuestraEdit } from 'api/types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import {
  form_number_to_string,
  form_string_to_number,
} from 'utilities/general';
import styles from './FormMuestra.styles';

const useStyles = makeStyles(styles);

const query_selector = (state: RootState) => ({
  muestras_colores: state.global.data.muestras_colores,
  tipo_muestras: state.global.data.tipo_muestras,
  muestra_cantidad_unidades: state.global.data.muestra_cantidad_unidades,
});

interface Props {
  form_id: string;
  datos_iniciales: ModelMuestraEdit;
  on_submit: (datos: ModelMuestra) => void;
  on_cancel: (event: FixLater) => void;
}

export default function FormMuestra(props: Props) {
  const classes = useStyles();
  const { datos_iniciales } = props;
  const [datos, definir_datos] = useState(datos_iniciales);

  const {
    muestras_colores,
    tipo_muestras,
    muestra_cantidad_unidades,
  } = useSelector(query_selector);

  const handle_change = (event: FixLater) => {
    const { name, type } = event.target;
    let value = event.target.value;

    if (type && type === 'number') {
      value = form_string_to_number(value);
    }

    definir_datos({
      ...datos,
      [name]: value,
    });
  };

  const emulate_event = (name: string, value: FixLater) => {
    const event = {
      target: {
        name,
        value,
      },
    };
    handle_change(event);
  };

  const handle_submit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    props.on_submit(datos as ModelMuestra);
  };

  return (
    <div className={classes.root}>
      <form
        className={classes.form}
        id={props.form_id}
        onSubmit={handle_submit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              value={datos.tipo}
              onChange={(event, valor) => {
                emulate_event('tipo', valor);
              }}
              options={tipo_muestras.ids}
              getOptionLabel={(id) => tipo_muestras.por_id[id].nombre}
              renderInput={(params) => (
                <TextField {...params} label="Muestra" required />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Ficho"
              type="number"
              name="ficho"
              value={form_number_to_string(datos.ficho)}
              onChange={handle_change}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Autocomplete
              value={datos.color}
              onChange={(event, valor) => {
                emulate_event('color', valor);
              }}
              options={muestras_colores.ids}
              getOptionLabel={(id) => muestras_colores.por_id[id].nombre}
              renderInput={(params) => <TextField {...params} label="Color" />}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              name="fuente"
              value={datos.fuente}
              onChange={handle_change}
              label="Fuente"
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              name="temperatura"
              value={form_number_to_string(datos.temperatura)}
              onChange={handle_change}
              label="Temperatura"
              type="number"
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              name="cantidad"
              value={form_number_to_string(datos.cantidad)}
              onChange={handle_change}
              label="Cantidad"
              type="number"
              InputProps={{ inputProps: { min: 1 } }}
              fullWidth
              required
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <Autocomplete
              value={datos.cantidad_unidad}
              onChange={(event, valor) => {
                emulate_event('cantidad_unidad', valor);
              }}
              options={muestra_cantidad_unidades.ids}
              getOptionLabel={(id) =>
                muestra_cantidad_unidades.por_id[id].nombre
              }
              renderInput={(params) => (
                <TextField {...params} label="Unidad" required />
              )}
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
