import DateFnsUtils from '@date-io/date-fns';
import { Button, DialogActions, Grid, makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ModelEnsayoPorMuestra } from 'api/types';
import esLocale from 'date-fns/locale/es';
import React from 'react';
import { editar_ensayo_por_muestra } from 'redux/orden_servicio/actions';
import { useTypedDispatch } from 'redux/store';
import styles from './DialogCrearEnsayos.styles';

const useStyles = makeStyles(styles);

interface Props {
  open: boolean;
  on_cancel: (event?: any) => void;
  ensayo: ModelEnsayoPorMuestra;
}

export default function DialogEditTest(props: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const full_screen = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useTypedDispatch();

  const initial_data = props.ensayo;

  const [data, set_data] = React.useState(initial_data);

  const handle_change = (event: FixLater) => {
    const { name, value } = event.target;
    set_data({
      ...data,
      [name]: value,
    });
  };

  const emulate_event = (name: string, value: FixLater) => {
    const event = {
      target: {
        name,
        value,
      },
    };
    handle_change(event);
  };

  const handle_submit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(editar_ensayo_por_muestra(data)).then(() => {
      props.on_cancel();
    });
  };

  const form_id = 'form-edit-samples';

  return (
    <Dialog
      fullScreen={full_screen}
      open={props.open}
      onClose={props.on_cancel}
      maxWidth={false}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">Editar ensayo</DialogTitle>

      <DialogContent className={classes.root}>
        <form className={classes.form} id={form_id} onSubmit={handle_submit}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <DateTimePicker
                  label="Fecha real inicio"
                  format="dd/MM/yyyy"
                  value={data.fecha_real_inicio}
                  onChange={(date) => {
                    emulate_event('fecha_real_inicio', date);
                  }}
                  autoOk
                  cancelLabel="Cancelar"
                  style={{ width: '100%' }}
                  invalidDateMessage="Formato de fecha invalido"
                  disableFuture={true}
                  maxDateMessage="La fecha no debe ser mayor al día de hoy"
                  clearable={true}
                  clearLabel="Limpiar"
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid item xs={12} sm={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <DateTimePicker
                  label="Fecha real fin"
                  format="dd/MM/yyyy"
                  value={data.fecha_real_fin}
                  onChange={(date) => {
                    emulate_event('fecha_real_fin', date);
                  }}
                  autoOk
                  cancelLabel="Cancelar"
                  style={{ width: '100%' }}
                  invalidDateMessage="Formato de fecha invalido"
                  disableFuture={true}
                  maxDateMessage="La fecha no debe ser mayor al día de hoy"
                  clearable={true}
                  clearLabel="Limpiar"
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </form>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={props.on_cancel}>
          Cancelar
        </Button>

        <Button
          variant="contained"
          color="primary"
          type="submit"
          form={form_id}>
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
