import React from 'react';
import * as Api from 'api/api';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import useDebounce from 'utilities/hooks/useDebounce';
import {
  get_empty_normalized_object,
  normalize_objects,
} from 'utilities/general';
import { ModelCliente, NormalizedModelCliente } from 'api/types';

interface Props {
  client_id?: number | null;
  on_change: (event: any, client_id: number | null) => void;
  required?: boolean;
}

export default function AsyncClientAutoComplete(props: Props) {
  const [search, set_search] = React.useState('');
  const debounced_search = useDebounce(search, 600);
  const [loading, set_loading] = React.useState(false);

  const [
    selecteted_client,
    set_selected_client,
  ] = React.useState<ModelCliente | null>(null);

  const [clients, set_clients] = React.useState<NormalizedModelCliente>(
    get_empty_normalized_object(),
  );

  React.useEffect(() => {
    let active = true;
    const clean = () => {
      active = false;
    };

    if (
      (props.client_id && !selecteted_client) ||
      (props.client_id &&
        selecteted_client &&
        selecteted_client.id &&
        props.client_id !== selecteted_client.id)
    ) {
      set_loading(true);
      Api.Cliente.detalle(props.client_id)
        .then((response) => {
          if (active) {
            set_clients(normalize_objects([response.data]));
            set_selected_client(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          set_loading(false);
        });
    }

    return clean;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.client_id]);

  React.useEffect(() => {
    let active = true;
    const clean = () => {
      active = false;
    };

    if (props.client_id && !selecteted_client) return clean;
    if (selecteted_client && selecteted_client.nombre === debounced_search)
      return clean;

    set_loading(true);
    const filters = { search: debounced_search, limit: 15, offset: 0 };
    Api.Cliente.listar(filters)
      .then((response) => {
        let _clients = response.data.results;
        if (active) set_clients(_clients);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        if (active) set_loading(false);
      });

    return clean;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounced_search]);

  const handle_change = (event: any, client: ModelCliente | null) => {
    set_selected_client(client ? client : null);
    props.on_change(event, client ? client.id : null);
  };

  return (
    <Autocomplete
      value={selecteted_client}
      onChange={handle_change}
      inputValue={search}
      onInputChange={(event, value) => {
        set_search(value);
      }}
      options={clients.ids ? clients.ids.map((id) => clients.por_id[id]) : []}
      getOptionLabel={(option) =>
        option && option.nombre ? option.nombre : ''
      }
      filterOptions={(options, state) => options}
      getOptionSelected={(option, value) => option.id === value.id}
      noOptionsText="Sin opciones"
      renderInput={(params) => (
        <TextField
          {...params}
          label="Cliente"
          placeholder="Buscar cliente"
          required={props.required}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
