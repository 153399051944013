import { StatisticCommentType } from 'api/consts';
import { NormalizedModelOrdenServicio } from 'api/types';
import { Action } from 'redux';

export const action_types = {
  SET_YEAR_FILTER: 'SET_YEAR_FILTER',
  SET_COMMENTS: 'SET_COMMENTS',
  SET_ORDERS: 'SET_ORDERS',
} as const;

export interface StatisticsState {
  ui: {
    filter_year: Date;
  };
  data: {
    comments: Record<StatisticCommentType, string>;
    orders: null | NormalizedModelOrdenServicio;
  };
}

export interface ActionSetYearFilter extends Action {
  type: typeof action_types.SET_YEAR_FILTER;
  year: Date;
}

export interface ActionSetComment extends Action {
  type: typeof action_types.SET_COMMENTS;
  type_comment: StatisticCommentType;
  comments: string;
}

export interface ActionSetOrders extends Action {
  type: typeof action_types.SET_ORDERS;
  orders: NormalizedModelOrdenServicio;
}

export type StatisticsActions =
  | ActionSetYearFilter
  | ActionSetComment
  | ActionSetOrders;
