export const LOGIN_PATH = '/login';
export const MAIN_PATH = '/';
export const ERROR_404_PATH = '404';

// Rutas de la barra lateral
export const ORDEN_SERVICIO_PATH = '/orden_servicio';
export const MUESTRA_PATH = '/orden_servicio/muestra';

export const GRAFICOS_PATH = '/graficos';
export const ESTADISTICAS_PATH = '/estadisticas';
export const ADMINISTRACION_PATH = '/administracion';
export const CERRAR_SESION_PATH = '/cerrar_sesion';

export const orden_servicio_detalle = (id?: number) => {
  return id ? `${ORDEN_SERVICIO_PATH}/${id}` : `${ORDEN_SERVICIO_PATH}/:id`;
};

export const muestra_detalle = (id?: number) => {
  return id ? `${MUESTRA_PATH}/${id}` : `${MUESTRA_PATH}/:id`;
};

// Para borrar
export const ORDEN_SERVICIO_CREAR_PATH = '/orden_servicio/crear';
export const MUESTRA_CREAR_PATH = '/muestra/crear';
