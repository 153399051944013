import {
  Card,
  CardHeader,
  CardProps,
  Divider,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import List from '@material-ui/core/List';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import {
  ModelEnsayoPorMuestra,
  ModelMuestra,
  NormalizedModelEnsayoPorMuestra,
} from 'api/types';
import AlertDialog from 'components/Dialogs/AlertDialog';
import { LISTA_ROLES_CREAR_EDITAR } from 'config/user_groups';
import ProtectedComponent from 'containers/auth/ProtectedComponent';
import DialogCrearEnsayos from 'containers/Dialogs/DialogEnsayo/DialogCrearEnsayos';
import DialogEditTest from 'containers/Dialogs/DialogEnsayo/DialogEditarEnsayo';
import React from 'react';
import { useSelector } from 'react-redux';
import { get_edit_mode } from 'redux/global/reducers';
import { eliminar_ensayo_por_muestra } from 'redux/orden_servicio/actions';
import { RootState } from 'redux/reducers';
import { useTypedDispatch } from 'redux/store';
import ItemTest from './ItemTest';

const query_selector = (state: RootState) => ({
  tipo_ensayos: state.global.data.tipo_ensayos,
  edit_mode: get_edit_mode(state),
});

interface Props extends CardProps {
  sample: ModelMuestra;
  tests: NormalizedModelEnsayoPorMuestra;
}

export default function CardTests(props: Props) {
  const { sample, tests, ...others_props } = props;
  const dispatch = useTypedDispatch();
  const { tipo_ensayos, edit_mode } = useSelector(query_selector);

  // For add test
  const [open_add_test, set_open_add_test] = React.useState(false);
  const handle_open_add_test = () => {
    set_open_add_test(true);
  };
  const handle_close_add_test = () => {
    set_open_add_test(false);
  };

  // For edit test
  const [
    edit_test,
    set_edit_test,
  ] = React.useState<ModelEnsayoPorMuestra | null>(null);
  const handle_close_edit_test = () => {
    set_edit_test(null);
  };

  // For delete test
  const [delete_test, set_delete_test] = React.useState<number | null>(null);
  const handle_accept_delete_test = () => {
    if (!delete_test) return;
    dispatch(eliminar_ensayo_por_muestra(delete_test)).then(() => {
      set_delete_test(null);
    });
  };
  const handle_cancel_delete_test = () => {
    set_delete_test(null);
  };

  return (
    <>
      <Card {...others_props}>
        <CardHeader
          title="Ensayos"
          action={
            edit_mode && (
              <ProtectedComponent roles={LISTA_ROLES_CREAR_EDITAR}>
                <Tooltip title="Agregar muestras">
                  <IconButton onClick={handle_open_add_test}>
                    <AddCircleIcon color="primary" style={{ fontSize: 35 }} />
                  </IconButton>
                </Tooltip>
              </ProtectedComponent>
            )
          }
        />

        <List>
          {tests.ids &&
            tests.ids.map((id, index, list) => (
              <div key={id}>
                <ItemTest
                  test={tests.por_id[id]}
                  tipo_ensayos={tipo_ensayos}
                  set_edit_test={set_edit_test}
                  set_delete_test={set_delete_test}
                  edit_mode={edit_mode}
                />
                {index < list.length - 1 && <Divider />}
              </div>
            ))}
        </List>
      </Card>

      {open_add_test && tests.ids && (
        <DialogCrearEnsayos
          muestra={sample.id}
          open={open_add_test}
          on_cancel={handle_close_add_test}
          exclude={tests.ids.map((id) => tests.por_id[id].tipo_ensayo)}
        />
      )}

      {edit_test && (
        <DialogEditTest
          ensayo={edit_test}
          open={Boolean(edit_test)}
          on_cancel={handle_close_edit_test}
        />
      )}

      <AlertDialog
        open={delete_test !== null}
        onAccept={handle_accept_delete_test}
        onCancel={handle_cancel_delete_test}
        title="¿Esta seguro?"
        content="¿Desea eliminar este ensayo?"
      />
    </>
  );
}
