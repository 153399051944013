import {
  Card,
  CardContent,
  CardHeader,
  CardProps,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { ModelOrdenServicioListar } from 'api/types';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import { formatear_fecha } from 'utilities/general';
import { get_order_id } from 'utilities/order_formaters';

const useStyles = makeStyles(
  createStyles({
    subTitle: {
      fontWeight: 'bold',
    },
  }),
);

const query_selector = (state: RootState) => ({
  orden_tomada_en: state.global.data.orden_tomada_en,
  especificaciones: state.global.data.especificaciones,
  sedes: state.global.data.sedes,
});

interface Props extends CardProps {
  orden_servicio: ModelOrdenServicioListar;
}

export default function CardOrden(props: Props) {
  const classes = useStyles();
  const { orden_servicio, ...others_props } = props;
  const { orden_tomada_en, especificaciones, sedes } = useSelector(
    query_selector,
  );

  return (
    <Card {...others_props}>
      <CardHeader
        title={`Id de orden: ${get_order_id(orden_servicio)}`}
        color="primary"
      />

      <CardContent>
        <div>
          <span className={classes.subTitle}>Fecha: </span>
          {formatear_fecha(orden_servicio.fecha_recibido, false)}
        </div>
        <div>
          <span className={classes.subTitle}>Sede: </span>
          {sedes.por_id[orden_servicio.sede].nombre}
        </div>
        <div>
          <span className={classes.subTitle}>Ubicación muestras: </span>
          {sedes.por_id[orden_servicio.ubicacion_muestras].nombre}
        </div>
        <div>
          <span className={classes.subTitle}>Cliente: </span>
          {orden_servicio.obra.cliente.nombre}
        </div>
        <div>
          <span className={classes.subTitle}>Obra: </span>
          {orden_servicio.obra.nombre}
        </div>
        <div>
          <span className={classes.subTitle}>Especificación: </span>
          {orden_servicio.especificacion &&
            especificaciones.por_id[orden_servicio.especificacion].nombre}
        </div>

        <div>
          <span className={classes.subTitle}>Responsable: </span>
          {orden_servicio.responsable}
        </div>
        <div>
          <span className={classes.subTitle}>Contacto: </span>
          {orden_servicio.contacto}
        </div>
        <div>
          <span className={classes.subTitle}>Tomado en: </span>
          {orden_servicio.tomada_en &&
            orden_tomada_en.por_id[orden_servicio.tomada_en].nombre}
        </div>
        <div>
          <span className={classes.subTitle}>Observaciones: </span>
          {orden_servicio.observaciones}
        </div>
        <div>
          <span className={classes.subTitle}>Orden recibida por: </span>
          {orden_servicio.usuario}
        </div>
      </CardContent>
    </Card>
  );
}
