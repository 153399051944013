import { StatisticCommentType } from './consts';

const urls = {
  TOKEN_OBTAIN: '/token/obtain/',
  TOKEN_REFRESH: '/token/refresh/',

  CLIENTES: '/nucleo/clientes/',
  OBRAS: '/nucleo/obras/',
  TIPO_ENSAYOS: '/nucleo/tipo_ensayos/',
  GRUPOS_TIPO_ENSAYOS: '/nucleo/grupos_tipo_ensayos/',
  TIPO_MUESTRAS: '/nucleo/tipo_muestras/',
  MUESTRA_COLORES: '/nucleo/muestra_colores/',
  ORDEN_TOMADA_EN: '/nucleo/orden_tomada_en/',
  MUESTRA_CANTIDAD_UNIDADES: '/nucleo/muestra_cantidad_unidades/',
  ESPECIFICACIONES: '/nucleo/especificaciones/',
  SEDES: '/nucleo/sedes',

  ORDENES_SERVICIOS: '/nucleo/ordenes_servicios/',
  MUESTRAS: '/nucleo/muestras/',
  ENSAYOS_POR_MUESTRA: '/nucleo/ensayos_por_muestra/',

  ESTADOS_TECNICOS: '/nucleo/estados_tecnicos/',
  ESTADOS_ORDEN_LABORATORIO: '/nucleo/estados_orden_laboratorio/',
  ESTADOS_ORDEN_TOTAL: '/nucleo/estados_orden_total/',

  ESTADISTICAS_COMENTARIOS: (
    year: number,
    comment_type: StatisticCommentType,
  ) => `/nucleo/estadisticas_comentarios/${year}/${comment_type}`,

  MEDIA: '/media',

  DJANGO_ADMIN: '/admin/',
} as const;

export default urls;
