import {
  createStyles,
  IconButton,
  Link,
  makeStyles,
  Theme,
  Tooltip,
} from '@material-ui/core';
import path from 'path';
import ClearSharpIcon from '@material-ui/icons/ClearSharp';
import CloudUploadSharpIcon from '@material-ui/icons/CloudUploadSharp';
import clsx from 'clsx';
import React from 'react';
import { useTypedDispatch } from 'redux/store';
import { open_tab_file } from 'redux/global/actions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: 'solid 1px',
      borderColor: theme.palette.grey[500],
      borderRadius: 4,
      height: '70px',
      padding: theme.spacing(0.8),
      display: 'flex',
      '&:hover': {
        borderColor: theme.palette.grey[800],
      },
    },
    withFiles: {
      justifyContent: 'space-between',
    },
    withOutFiles: {
      justifyContent: 'center',
    },
    rootDisabled: {
      color: theme.palette.text.disabled,
      borderStyle: 'dotted',
      pointerEvents: 'none',
    },
    uploadButton: {
      margin: theme.spacing(1),
    },
  }),
);

const random_string = () => {
  return Math.random().toString(36);
};

interface ShowFileProps {
  file: File | string;
}

function ShowFile({ file }: ShowFileProps) {
  const dispatch = useTypedDispatch();

  if (typeof file === 'string') {
    return (
      <Link
        href=""
        onClick={(event: any) => {
          event.preventDefault();
          dispatch(open_tab_file(file));
        }}>
        {decodeURIComponent(path.basename(file))}
      </Link>
    );
  }
  return <label>{file.name}</label>;
}

interface InputFileProps {
  file: string | File | null;
  accept?: string;
  on_change: (file: File | null) => void;
  disabled?: boolean;
  tooltip_title?: string;
}

export default function InputFile(props: InputFileProps) {
  const {
    file,
    accept,
    on_change,
    disabled = false,
    tooltip_title = 'Subir archivo',
  } = props;

  const classes = useStyles();
  const [input_key, set_input_key] = React.useState(random_string());

  const handle_change = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    on_change(file);
  };

  const clear_input = () => {
    on_change(null);
    set_input_key(random_string());
  };

  const rootClass = clsx(
    classes.root,
    file ? classes.withFiles : classes.withOutFiles,
    disabled && classes.rootDisabled,
  );

  return (
    <div className={rootClass}>
      <input
        accept={accept}
        key={input_key}
        type="file"
        hidden
        id="input-upload-file"
        onChange={handle_change}
        disabled={disabled}
      />
      <div>
        <label htmlFor="input-upload-file" className={classes.uploadButton}>
          <Tooltip title={tooltip_title}>
            <IconButton size="small" component="span" disabled={disabled}>
              <CloudUploadSharpIcon style={{ fontSize: 50 }} />
            </IconButton>
          </Tooltip>
        </label>

        {file && <ShowFile file={file} />}
      </div>

      {Boolean(file) && (
        <Tooltip title="Limpiar">
          <IconButton onClick={clear_input} disabled={disabled}>
            <ClearSharpIcon />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
}
