import {
  Card,
  CardContent,
  CardProps,
  createStyles,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import CloudDownloadSharpIcon from '@material-ui/icons/CloudDownloadSharp';
import { ModelOrdenServicioListar } from 'api/types';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import { formatear_fecha } from 'utilities/general';
import { useTypedDispatch } from 'redux/store';
import { download_file, open_tab_file } from 'redux/global/actions';

const useStyles = makeStyles(
  createStyles({
    subTitle: {
      fontWeight: 'bold',
    },
  }),
);

const query_selector = (state: RootState) => ({
  estados_orden_laboratorio: state.global.data.estados_orden_laboratorio,
  estados_orden_total: state.global.data.estados_orden_total,
});

interface Props extends CardProps {
  orden_servicio: ModelOrdenServicioListar;
}

export default function CardCalc(props: Props) {
  const classes = useStyles();
  const { orden_servicio, ...others_props } = props;
  const { estados_orden_laboratorio, estados_orden_total } = useSelector(
    query_selector,
  );
  const dispatch = useTypedDispatch();

  return (
    <Card {...others_props}>
      <CardContent>
        <Typography variant="h6" color="primary">
          Estado
        </Typography>

        <div>
          <span className={classes.subTitle}>Estado orden laboratorio: </span>
          {orden_servicio.calc.estado_orden_laboratorio &&
            estados_orden_laboratorio.por_id[
              orden_servicio.calc.estado_orden_laboratorio
            ].nombre}
        </div>

        <div>
          <span className={classes.subTitle}>Estado orden total: </span>
          {orden_servicio.calc.estado_orden_total &&
            estados_orden_total.por_id[orden_servicio.calc.estado_orden_total]
              .nombre}
        </div>

        <Typography variant="h6" color="primary">
          Programación
        </Typography>
        <div>
          <span className={classes.subTitle}>
            Fecha programada salida laboratorio:{' '}
          </span>
          {orden_servicio.calc.fecha_programada_salida_laboratorio &&
            formatear_fecha(
              orden_servicio.calc.fecha_programada_salida_laboratorio,
              false,
            )}
        </div>

        <div>
          <span className={classes.subTitle}>
            Fecha programada fin cálculo y digitación:{' '}
          </span>
          {orden_servicio.calc.fecha_programada_fin_calculo_digitacion &&
            formatear_fecha(
              orden_servicio.calc.fecha_programada_fin_calculo_digitacion,
              false,
            )}
        </div>

        <div>
          <span className={classes.subTitle}>
            Fecha programada salida informe:{' '}
          </span>
          {orden_servicio.calc.fecha_programada_salida_informe &&
            formatear_fecha(
              orden_servicio.calc.fecha_programada_salida_informe,
              false,
            )}
        </div>

        {/* <div>
            <span className={classes.subTitle}>
              Duración programada orden laboratorio:{' '}
            </span>
            {orden_servicio.calc.duracion_programada_orden_laboratorio &&
              orden_servicio.calc.duracion_programada_orden_laboratorio +
                ' días hábiles'}
          </div> */}

        <Typography variant="h6" color="primary">
          Fechas reales
        </Typography>

        <div>
          <span className={classes.subTitle}>
            Fecha real salida laboratorio:{' '}
          </span>
          {orden_servicio.calc.fecha_real_salida_laboratorio &&
            formatear_fecha(
              orden_servicio.calc.fecha_real_salida_laboratorio,
              false,
            )}
        </div>

        <div>
          <span className={classes.subTitle}>
            Fecha real fin calculo y digitación:{' '}
          </span>
          {orden_servicio.fecha_real_fin_calculo_digitacion &&
            formatear_fecha(
              orden_servicio.fecha_real_fin_calculo_digitacion,
              false,
            )}
        </div>
        <div>
          <span className={classes.subTitle}>
            Días real cálculo y digitación:{' '}
          </span>
          {orden_servicio.calc.dias_real_calculo_digitacion &&
            orden_servicio.calc.dias_real_calculo_digitacion + ' días hábiles'}
        </div>

        {/* <div>
            <span className={classes.subTitle}>
              Desviación duración orden laboratorio:{' '}
            </span>
            {orden_servicio.calc.desviacion_duracion_orden_laboratorio &&
              orden_servicio.calc.desviacion_duracion_orden_laboratorio +
                ' días'}
          </div>

          <div>
            <span className={classes.subTitle}>
              Desviación duración orden total:{' '}
            </span>
            {orden_servicio.calc.desviacion_duracion_orden_total &&
              orden_servicio.calc.desviacion_duracion_orden_total + ' días'}
          </div> */}

        <Typography variant="h6" color="primary">
          Informe
        </Typography>
        {orden_servicio.genera_informe ? (
          <>
            <div>
              <span className={classes.subTitle}>
                Fecha real salida informe:{' '}
              </span>
              {orden_servicio.fecha_real_salida_informe &&
                formatear_fecha(
                  orden_servicio.fecha_real_salida_informe,
                  false,
                )}
            </div>
            <div>
              <span className={classes.subTitle}>Número de informe: </span>
              {orden_servicio.numero_informe && orden_servicio.numero_informe}
            </div>
            {orden_servicio.informe && (
              <div>
                <span className={classes.subTitle}>Informe: </span>
                <IconButton
                  onClick={() => {
                    dispatch(open_tab_file(orden_servicio.informe));
                  }}>
                  <PictureAsPdfIcon />
                </IconButton>
                <IconButton
                  onClick={() => {
                    dispatch(download_file(orden_servicio.informe));
                  }}>
                  <CloudDownloadSharpIcon />
                </IconButton>
              </div>
            )}
          </>
        ) : (
          <div>No genera informe</div>
        )}
      </CardContent>
    </Card>
  );
}
