import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Statistics from './Statistics';
import { useDispatch } from 'react-redux';
import { set_app_bar_title } from 'redux/global/actions';
import { Grid } from '@material-ui/core';
import FilterYear from './FilterYear';
import TabPanel, { a11yProps } from 'components/TabPanel';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: theme.spacing(0),
  },
  filter: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
}));

const tab_names = ['Laboratorio', 'Cálculo y digitacion', 'Informe'];

enum EnumTabs {
  LABORATORIO = 0,
  CALCULO_DIGITACION,
  INFORME,
}

export default function SimpleTabs() {
  const classes = useStyles();
  const [tab, set_tab] = React.useState(EnumTabs.LABORATORIO);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(set_app_bar_title('Estadísticas ' + tab_names[tab]));
    return () => {
      dispatch(set_app_bar_title(''));
    };
  }, [tab, dispatch]);

  const handle_change_tab = (event: any, value: number) => {
    set_tab(value);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Tabs
            value={tab}
            onChange={handle_change_tab}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="simple tabs example">
            <Tab
              label={tab_names[EnumTabs.LABORATORIO]}
              {...a11yProps(EnumTabs.LABORATORIO)}
            />
            {/* <Tab
              label={tab_names[EnumTabs.CALCULO_DIGITACION]}
              {...a11yProps(EnumTabs.CALCULO_DIGITACION)}
            />
            <Tab
              label={tab_names[EnumTabs.INFORME]}
              {...a11yProps(EnumTabs.INFORME)}
            /> */}
          </Tabs>
        </Grid>

        <Grid item xs={12} md={4}>
          <div className={classes.filter}>
            <FilterYear />
          </div>
        </Grid>
      </Grid>

      <TabPanel value={tab} index={EnumTabs.LABORATORIO}>
        <Statistics />
      </TabPanel>
      <TabPanel value={tab} index={EnumTabs.CALCULO_DIGITACION}>
        <Statistics />
      </TabPanel>
      <TabPanel value={tab} index={EnumTabs.INFORME}>
        <Statistics />
      </TabPanel>
    </div>
  );
}
