import { createStore, applyMiddleware, compose } from 'redux';
import thunk, { ThunkAction, ThunkMiddleware } from 'redux-thunk';
import reducers, { AppActions, RootState } from './reducers';
import { useDispatch } from 'react-redux';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const thunk_mw: ThunkMiddleware<RootState, AppActions> = thunk;

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(thunk_mw)),
);
export default store;
export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AppActions
>;

export const useTypedDispatch = () => useDispatch<AppDispatch>();
