import * as Api from 'api/api';
import * as paths from 'config/paths';
import path from 'path';
import { AppThunk } from 'redux/store';
import {
  ActionSetAppBarTitle,
  ActionSetBreadcrumbs,
  ActionSetInitialData,
  ActionSetLoading,
  ActionToogleEditMode,
  action_types,
  BreadcrumbPath,
  InitialData,
} from './types';

/**
 * Set app bar title
 */
export const set_app_bar_title = (value: string): ActionSetAppBarTitle => ({
  type: action_types.SET_APP_BAR_TITLE,
  value,
});

/**
 * Set state to loading
 */
export const set_loading = (value: boolean): ActionSetLoading => ({
  type: action_types.SET_LOADING,
  value,
});

/**
 * Set basic initial data to redux
 */
export const set_initial_data = (value: InitialData): ActionSetInitialData => ({
  type: action_types.SET_INITIAL_DATA,
  value,
});

export const handle_http_error = (error: FixLater) => () => {
  console.log(error);
};

/**
 * Get initial data of application
 */
export const get_initial_data = (): AppThunk => (dispatch) => {
  dispatch(set_loading(true));

  return Promise.all([
    Api.obtener_tipo_ensayos(),
    Api.obtener_grupos_tipo_ensayos(),
    Api.obtener_tipo_muestras(),
    Api.obtener_muestras_colores(),
    Api.obtener_orden_tomada_en(),
    Api.obtener_muestra_cantidad_unidades(),
    Api.obtener_especificaciones(),
    Api.obtener_estados_tecnicos(),
    Api.obtener_estados_orden_laboratorio(),
    Api.obtener_estados_orden_total(),
    Api.obtener_sedes(),
  ])
    .then((response) => {
      const data: InitialData = {
        tipo_ensayos: response[0].data,
        grupos_tipo_ensayos: response[1].data,
        tipo_muestras: response[2].data,
        muestras_colores: response[3].data,
        orden_tomada_en: response[4].data,
        muestra_cantidad_unidades: response[5].data,
        especificaciones: response[6].data,
        estados_tecnicos: response[7].data,
        estados_orden_laboratorio: response[8].data,
        estados_orden_total: response[9].data,
        sedes: response[10].data,
      };
      return dispatch(set_initial_data(data));
    })
    .catch((error) => dispatch(handle_http_error(error)))
    .finally(() => dispatch(set_loading(false)));
};

export const toogle_edit_mode = (): ActionToogleEditMode => ({
  type: action_types.TOOGLE_EDIT_MODE,
});

/**
 * @param breadcrumbs List of breadcrumb
 */
const set_breadcrumbs = (
  breadcrumbs: BreadcrumbPath[],
): ActionSetBreadcrumbs => ({
  type: action_types.SET_BREADCRUMBS,
  value: breadcrumbs,
});

/**
 * @param orders Include orders?
 * @param order_id
 * @param sample_id
 */
export const set_order_breadcrumbs = (
  orders: boolean,
  order_id?: number,
  sample_id?: number,
): AppThunk => (dispatch) => {
  let breadcrumbs: BreadcrumbPath[] = [];
  if (orders) {
    breadcrumbs.push({ name: 'Ordenes', path: paths.ORDEN_SERVICIO_PATH });
    if (order_id) {
      breadcrumbs.push({
        name: 'Orden detalle',
        path: paths.orden_servicio_detalle(order_id),
      });
      if (sample_id) {
        breadcrumbs.push({
          name: 'Muestra',
          path: paths.muestra_detalle(sample_id),
        });
      }
    }
  }
  dispatch(set_breadcrumbs(breadcrumbs));

  const app_bar_title = breadcrumbs.length
    ? breadcrumbs[breadcrumbs.length - 1].name
    : '';
  dispatch(set_app_bar_title(app_bar_title));
};

/**
 * Download a file from api
 * @param url Url of file to download
 * @param _name File name
 */
export const download_file = (url: string, _name?: string): AppThunk => (
  dispatch,
) => {
  dispatch(set_loading(true));

  return Api.get_media_file(url)
    .then((response) => {
      const name = _name ? _name : decodeURIComponent(path.basename(url));

      // TODO workaround large files disables ui for long time
      const blob_url = window.URL.createObjectURL(response.data);
      let temp_link = window.document.createElement('a');
      temp_link.href = blob_url;
      temp_link.setAttribute('download', name);
      temp_link.click();
      window.URL.revokeObjectURL(blob_url);
    })
    .catch((error) => dispatch(handle_http_error(error)))
    .finally(() => dispatch(set_loading(false)));
};

/**
 * Open file in new tab
 * @param url Url of file to open
 */
export const open_tab_file = (url: string): AppThunk => (dispatch) => {
  dispatch(set_loading(true));

  return Api.get_media_file(url)
    .then((response) => {
      // TODO workaround large files disables ui for long time
      const blob_url = window.URL.createObjectURL(response.data);
      let temp_link = window.document.createElement('a');
      temp_link.href = blob_url;
      temp_link.target = '_blank';
      temp_link.click();

      // For Firefox it is necessary to delay revoking the ObjectURL
      setTimeout(function () {
        window.URL.revokeObjectURL(blob_url);
      }, 1000);
    })
    .catch((error) => dispatch(handle_http_error(error)))
    .finally(() => dispatch(set_loading(false)));
};
