import { Breadcrumbs, Hidden, Switch, Tooltip } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import clsx from 'clsx';
import Copyright from 'components/Copyright';
import HeaderLoading from 'components/Loading/HeaderLoading';
import LinkRouter from 'components/material-ui/LinkRouter';
import * as paths from 'config/paths';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  NavLink,
  Redirect,
  Route,
  Switch as RouteSwitch,
} from 'react-router-dom';
import { get_initial_data, toogle_edit_mode } from 'redux/global/actions';
import { get_breadcrumbs, get_edit_mode } from 'redux/global/reducers';
import { RootState } from 'redux/reducers';
import { useTypedDispatch } from 'redux/store';
import useWindowSize from 'utilities/hooks/useWindowSize';
import Error404 from 'views/Errores/404/404';
import MuestraDetalle from 'views/Muestra/MuestraDetalle/MuestraDetalle';
import OrdenServicioDetalle from 'views/OrdenServicio/OrdenServicioDetalle/OrdenServicioDetalle';
import styles from './Admin.styles';
import routes, { grupos } from './routes';

const switchRoutes = (
  <RouteSwitch>
    {routes.map((prop, key) => (
      <Route exact path={prop.path} component={prop.Component} key={key} />
    ))}

    <Route
      component={OrdenServicioDetalle}
      path={paths.orden_servicio_detalle()}
      exact
    />

    <Route component={MuestraDetalle} path={paths.muestra_detalle()} exact />

    <Redirect from={paths.MAIN_PATH} to={paths.ORDEN_SERVICIO_PATH} exact />
    <Route path="*" component={Error404} />
  </RouteSwitch>
);

const useStyles = makeStyles(styles);

const query_selector = (state: RootState) => ({
  app_bar_title: state.global.ui.app_bar_title,
  loading: state.global.ui.loading,
  edit_mode: get_edit_mode(state),
  breadcrumbs: get_breadcrumbs(state),
  initial_data_loaded: state.global.data.initial_data_loaded,
});

export default function Admin() {
  const classes = useStyles();
  const theme = useTheme();
  const { width } = useWindowSize();
  const dispatch = useTypedDispatch();
  const redux_state = useSelector(query_selector);

  const [open, set_open] = React.useState(false);
  const handleDrawerToogle = () => {
    set_open(!open);
  };
  const set_initial_open = () => {
    if (width <= theme.breakpoints.values.lg) set_open(false);
    else set_open(true);
  };

  const handle_click = (event: any) => {
    if (width <= theme.breakpoints.values.md) set_open(false);
  };

  useEffect(() => {
    set_initial_open();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  useEffect(() => {
    set_initial_open();
    dispatch(get_initial_data());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // verifies if route_name is the one active (in browser input)
  function active_route(route_name: string) {
    return window.location.href.indexOf(route_name) > -1 ? true : false;
  }

  const drawer = (
    <>
      <div className={classes.toolbarIcon}>
        <IconButton onClick={handleDrawerToogle} color="inherit">
          <MenuIcon />
        </IconButton>
      </div>

      {grupos.map((grupo) => (
        <div key={grupo.nombre}>
          <Divider />
          <ListSubheader inset>{grupo.titulo}</ListSubheader>
          <List>
            {routes
              .filter((r) => r.grupo === grupo.nombre)
              .map((prop, key) => (
                <NavLink
                  to={prop.path}
                  onClick={handle_click}
                  className={clsx(
                    classes.item,
                    active_route(prop.path) && classes.itemSelected,
                  )}
                  activeClassName="active"
                  key={key}>
                  <ListItem button>
                    <ListItemIcon>
                      <prop.Icon />
                    </ListItemIcon>
                    <ListItemText primary={prop.name} />
                  </ListItem>
                </NavLink>
              ))}
          </List>
        </div>
      ))}
    </>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />

      <HeaderLoading loading={Boolean(redux_state.loading)} />

      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToogle}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden,
            )}>
            <MenuIcon />
          </IconButton>

          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}>
            {redux_state.app_bar_title}
          </Typography>

          <Tooltip title="Modo edición">
            <Switch
              color="secondary"
              checked={redux_state.edit_mode}
              onChange={() => {
                dispatch(toogle_edit_mode());
              }}
            />
          </Tooltip>
        </Toolbar>
      </AppBar>

      <Hidden smDown implementation="js">
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}>
          {drawer}
        </Drawer>
      </Hidden>

      <Hidden mdUp implementation="js">
        <Drawer
          variant="temporary"
          open={open}
          onClose={handleDrawerToogle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}>
          {drawer}
        </Drawer>
      </Hidden>

      <main className={classes.content}>
        <div className={classes.appBarSpacer} />

        <Breadcrumbs
          className={classes.breadcrumbs}
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb">
          {redux_state.breadcrumbs.map((value, index, list) =>
            index < list.length - 1 ? (
              <LinkRouter color="inherit" to={value.path} key={value.path}>
                {value.name}
              </LinkRouter>
            ) : (
              <Typography color="textPrimary" key={value.path}>
                {value.name}
              </Typography>
            ),
          )}
        </Breadcrumbs>

        <Container maxWidth={false} className={classes.container}>
          {redux_state.initial_data_loaded && switchRoutes}
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}
