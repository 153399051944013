import { Token } from 'api/types';
import { ADMIN } from 'config/user_groups';
import { to_array } from './general';

/**
 * Verificar que el token tenga al menos uno de los roles requeridos
 */
export function verificar_roles(
  token: Token,
  _roles: string | readonly string[],
): boolean {
  let roles = to_array(_roles);

  if (roles.length === 0) return true;

  // Admin has all permisions
  roles = roles.concat(ADMIN);

  for (const rol of roles) {
    if (token.groups.includes(rol)) {
      return true;
    }
  }
  return false;
}

/**
 * Verificar el token y los roles requeridos
 */
export function verificar_token(
  token?: Token | null,
  roles: string | readonly string[] = [],
): boolean {
  if (token) {
    return verificar_roles(token, roles);
  }
  return false;
}

export class RefreshToken {
  static REFRES_TOKEN_KEY = 'refresh_token';

  static guardar(refresh_token: string) {
    localStorage.setItem(this.REFRES_TOKEN_KEY, refresh_token);
  }

  static obtener() {
    return localStorage.getItem(this.REFRES_TOKEN_KEY);
  }

  static borrar() {
    localStorage.removeItem(this.REFRES_TOKEN_KEY);
  }
}
