import { Button, DialogActions } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ModelMuestra } from 'api/types';
import React from 'react';
import { actualizar_muestra } from 'redux/orden_servicio/actions';
import { useTypedDispatch } from 'redux/store';
import FormMuestra from './FormMuestra';

interface Props {
  open: boolean;
  muestra: ModelMuestra;
  on_cancel: (event?: FixLater) => void;
}

export default function DialogEditSample(props: Props) {
  const theme = useTheme();
  const full_screen = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useTypedDispatch();

  const handle_submit = (data: ModelMuestra) => {
    dispatch(actualizar_muestra(data)).then(() => {
      props.on_cancel();
    });
  };

  const form_id = 'form-edit-sample';

  return (
    <Dialog
      fullScreen={full_screen}
      open={props.open}
      onClose={props.on_cancel}
      maxWidth={false}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">Editar muestra</DialogTitle>

      <DialogContent>
        <FormMuestra
          form_id={form_id}
          datos_iniciales={props.muestra}
          on_submit={handle_submit}
          on_cancel={props.on_cancel}
        />
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={props.on_cancel}>
          Cancelar
        </Button>

        <Button
          variant="contained"
          color="primary"
          type="submit"
          form={form_id}>
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
