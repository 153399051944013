import {
  ModelEnsayoPorMuestra,
  ModelMuestra,
  ModelOrdenServicioListar,
} from 'api/types';
import produce from 'immer';
import { RootState } from 'redux/reducers';
import {
  array_delete_value,
  get_empty_normalized_object,
} from 'utilities/general';
import {
  OrdenServicioActions,
  OrdenServicioState,
  action_types,
} from './types';

const estado_inicial: OrdenServicioState = {
  ui: {
    filtro_id_orden: null,
    filtro_cliente: null,
    filtro_estado_laboratorio: null,
    filtro_estado_total: null,
    filtro_fecha_inicial: null,
    filtro_fecha_final: null,
    filtro_tipo_muestra: null,

    paginacion: {
      page: 1,
      limit: 15,
      count: null,
    },
  },
  datos: {
    // Listado de ordenes de servicio
    ordenes_servicios: get_empty_normalized_object<ModelOrdenServicioListar>(),

    // Detalles de una orden de servicio
    orden_servicio: null,
    muestras: get_empty_normalized_object<ModelMuestra>(),

    // Detalle de una muestra
    muestra: null,
    ensayos_por_muestra: get_empty_normalized_object<ModelEnsayoPorMuestra>(),
  },
} as const;

const orden_servicio = (state = estado_inicial, action: OrdenServicioActions) =>
  produce(state, (draft) => {
    switch (action.type) {
      // UI
      case action_types.DEFINIR_FILTRO_ID_ORDEN:
        draft.ui.filtro_id_orden = action.value;
        break;
      case action_types.DEFINIR_FILTRO_CLIENTE:
        draft.ui.filtro_cliente = action.value;
        draft.ui.paginacion.page = estado_inicial.ui.paginacion.page;
        break;
      case action_types.DEFINIR_FILTRO_ESTADO_LABORATORIO:
        draft.ui.filtro_estado_laboratorio = action.value;
        draft.ui.paginacion.page = estado_inicial.ui.paginacion.page;
        break;
      case action_types.DEFINIR_FILTRO_ESTADO_TOTAL:
        draft.ui.filtro_estado_total = action.value;
        draft.ui.paginacion.page = estado_inicial.ui.paginacion.page;
        break;
      case action_types.DEFINIR_FILTRO_FECHA_INICIAL:
        draft.ui.filtro_fecha_inicial = action.value;
        draft.ui.paginacion.page = estado_inicial.ui.paginacion.page;
        break;
      case action_types.DEFINIR_FILTRO_FECHA_FINAL:
        draft.ui.filtro_fecha_final = action.value;
        draft.ui.paginacion.page = estado_inicial.ui.paginacion.page;
        break;
      case action_types.DEFINIR_FILTRO_TIPO_MUESTRA:
        draft.ui.filtro_tipo_muestra = action.value;
        draft.ui.paginacion.page = estado_inicial.ui.paginacion.page;
        break;
      case action_types.DEFINIR_PAGINACION:
        draft.ui.paginacion = { ...draft.ui.paginacion, ...action.value };
        break;

      // Ordenes de servicio
      case action_types.DEFINIR_ORDENES_SERVICIOS:
        draft.datos.ordenes_servicios = action.value;
        break;
      case action_types.AGREGAR_ORDEN:
        draft.datos.ordenes_servicios.ids.unshift(action.value.id);
        draft.datos.ordenes_servicios.por_id[action.value.id] = action.value;
        break;
      case action_types.ACTUALIZAR_ORDEN:
        draft.datos.ordenes_servicios.por_id[action.value.id] = action.value;
        break;
      case action_types.ELIMINAR_ORDEN:
        draft.datos.ordenes_servicios.ids = array_delete_value(
          draft.datos.ordenes_servicios.ids,
          action.id,
        );
        break;

      // Detalles de una orden de servicio
      case action_types.DEFINIR_ORDEN_SERVICIO_DETALLE:
        draft.datos.orden_servicio = action.value;
        break;
      case action_types.DEFINIR_MUESTRAS:
        draft.datos.muestras = action.value;
        break;
      case action_types.AGREGAR_MUESTRA:
        draft.datos.muestras.ids.unshift(action.value.id);
        draft.datos.muestras.por_id[action.value.id] = action.value;
        break;
      case action_types.ACTUALIZAR_MUESTRA:
        draft.datos.muestras.por_id[action.value.id] = action.value;
        break;
      case action_types.ELIMINAR_MUESTRA:
        draft.datos.muestras.ids = array_delete_value(
          draft.datos.muestras.ids,
          action.id,
        );
        break;

      // Detalles de una muestra
      case action_types.DEFINIR_MUESTRA_DETALLE:
        draft.datos.muestra = action.value;
        break;
      case action_types.DEFINIR_ENSAYOS_POR_MUESTRA:
        draft.datos.ensayos_por_muestra = action.value;
        break;
      case action_types.AGREGAR_ENSAYOS_POR_MUESTRA:
        draft.datos.ensayos_por_muestra.ids.unshift(...action.value.ids);
        draft.datos.ensayos_por_muestra.por_id = {
          ...draft.datos.ensayos_por_muestra.por_id,
          ...action.value.por_id,
        };
        break;
      case action_types.ACTUALIZAR_ENSAYO_POR_MUESTRA:
        draft.datos.ensayos_por_muestra.por_id[action.value.id] = action.value;
        break;
      case action_types.ELIMINAR_ENSAYO_POR_MUESTRA:
        draft.datos.ensayos_por_muestra.ids = array_delete_value(
          draft.datos.ensayos_por_muestra.ids,
          action.id,
        );
        delete draft.datos.ensayos_por_muestra.por_id[action.id];
        break;

      default:
        return draft;
    }
  });

export function obtener_numero_paginas(state: RootState) {
  const { count, limit } = state.orden_servicio.ui.paginacion;
  if (count) {
    return Math.ceil(count / limit);
  }
  return null;
}

export const query_filters = (state: RootState) => ({
  filtro_id_orden: state.orden_servicio.ui.filtro_id_orden,
  filtro_cliente: state.orden_servicio.ui.filtro_cliente,
  filtro_estado_laboratorio: state.orden_servicio.ui.filtro_estado_laboratorio,
  filtro_estado_total: state.orden_servicio.ui.filtro_estado_total,
  filtro_fecha_inicial: state.orden_servicio.ui.filtro_fecha_inicial,
  filtro_fecha_final: state.orden_servicio.ui.filtro_fecha_final,
  filtro_tipo_muestra: state.orden_servicio.ui.filtro_tipo_muestra,
});

export default orden_servicio;
